import { Component } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    templateUrl: './confirmation.component.html'
})
export class ConfirmationComponent {

    //public onClose: Subject<boolean>;
     onClose=new Subject<boolean>();
  subject: Subject<boolean>;
  heading: Subject<string>;
  message: Subject<string>;
    constructor(private _bsModalRef: BsModalRef) {

    }

    public ngOnInit(): void {
        //this.onClose = new Subject();
    }
  action(value: boolean) {
    this._bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }
  //public onConfirm(): void {
  //  this._bsModalRef.hide();
  //  this.onClose.next(true);
  //  this.onClose.complete();
        
  //  }

  //public onCancel(): void {
  //  this._bsModalRef.hide();
  //  this.onClose.next(false);
  //  this.onClose.complete();
  //  }
}
