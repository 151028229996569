import { Component } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './confirmationProduct.component.html'
})
export class confirmationProductComponent {

    onClose=new Subject<boolean>();
    subject: Subject<boolean>;
    heading: Subject<string>;
    message: Subject<string>;
    cancelButtonText: Subject<string>;
    SubmitButtonText: Subject<string>;

    constructor(private _bsModalRef: BsModalRef) {

    }

    public ngOnInit(): void {
        //this.onClose = new Subject();
    }
  action(value: boolean) {
    this._bsModalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }
}
