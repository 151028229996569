export class userModel {
  userId: number;
  userName: string;
  firstName: string;
  lastName: string;
  roleName: string;
  isLoggedIn: boolean;
  roleId: string;
  isSupervisor: boolean;
  listuserPage: Array<userPages>;
  isAdmin: boolean;
}
export class userPages {
  pageName: string;
}
