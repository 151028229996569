import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {userModel,userPages } from '../Model/userDetails';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  userDataSubscription: any;
  userData = new userModel();

  constructor(private router: Router, private authService: AuthService) {
   
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    this.authService.setUserDetails();
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });
    let roles = next.data["roles"] as Array<string>;

    if (this.userData.hasOwnProperty('userId')) {
      var list = this.userData.listuserPage.filter(x => x.pageName == roles[0]);
      if (this.userData.userId > 0) {
        if (list != null && list.length > 0) {
          return true;
        }
        return true;
      }

      if (this.userData.userId > 0) {
        return true;
      }
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
