import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()

export class BackEndUrlService {
  private previousUrl: string | undefined;
  private currentUrl: string;
  localUrl: string = "http://localhost:49595/";
 // liveUrl: string = "https://demo.tagsweeklyforce.com/";
  liveUrl: string = "https://skuvelocity.com/";
  parsedUrl:any = new URL(window.location.href);
  baseUrl = this.parsedUrl.origin;  
  backendUrl: string = this.baseUrl+'/';
  apiUrl='http://localhost:49595/api/'
  constructor(private router: Router)
  {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

}
