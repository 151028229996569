import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  msgs: any[] = [];
  private mySubscription: any;

  //constructor(
  //  private authService: AuthService,
  //  private router: Router,
  //  private activatedRoute: ActivatedRoute
  //) {
  //  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //  this.mySubscription = this.router.events.subscribe((event) => {
  //   // debugger;
  //    if (event instanceof NavigationEnd) {
  //      if (event.urlAfterRedirects !== '/login') {

  //        /*sessionStorage.setItem(`lastUrl`, JSON.stringify(event.urlAfterRedirects));*/
  //        sessionStorage.setItem(`lastUrl`, event.urlAfterRedirects);
  //      }
  //    }
  //  });
  //  window.addEventListener('storage', this.handleStorageEvent.bind(this));
  //}
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    if (localStorage.getItem('authToken')) {
      this.authService.setUserDetails();
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      // debugger;
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects !== '/login') {

          /*sessionStorage.setItem(`lastUrl`, JSON.stringify(event.urlAfterRedirects));*/
          sessionStorage.setItem(`lastUrl`, event.urlAfterRedirects);
        }
      }
    });
    window.addEventListener('storage', this.handleStorageEvent.bind(this));
  }
  ngOnInit() {
    this.handleInitialNavigation();
  }
  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    window.removeEventListener('storage', this.handleStorageEvent.bind(this));
  }
  private handleInitialNavigation() {
 //debugger;

    //const authToken = localStorage.getItem('authToken');
   
    //const url = window.location.pathname;
  
    //if (authToken) {
    //  this.authService.setUserDetails();
    //  //const lastUrl = JSON.parse(sessionStorage.getItem(`lastUrl`));
    //  const lastUrl = sessionStorage.getItem(`lastUrl`);
    //  if (lastUrl && lastUrl !== '/login') {
    //    this.router.navigate([lastUrl]);
    //    sessionStorage.removeItem('lastUrl');
    //  }
    //  else if(url && url !== '/login'){
    //    this.router.navigate([url])
    //  }
    //  //if (url && url !== '/login') {
    //  //  this.router.navigate([url]);
    //  //} else if (lastUrl && lastUrl !== '/login') {
    //  //  this.router.navigate([lastUrl]);
    //  //}
    //  else {
    //    this.router.navigate(['/admin/dashboard']);
    //  }
    //}
    const authToken = localStorage.getItem('authToken');

    const url = window.location.pathname;
    const previousUrl = sessionStorage.getItem('previousUrl');

    if (authToken) {
      this.authService.setUserDetails();

      const url = window.location.pathname;
      const lastUrl = sessionStorage.getItem('lastUrl');
      //debugger;
      // Handle the case where the user pastes a URL in a new tab
      if (url && url !== '/login') {
        sessionStorage.setItem('lastUrl', url);
        this.router.navigate([url]); // Navigate to the pasted URL
      }
      // If there's a lastUrl stored and it's not /login, navigate to it
      else if (lastUrl && lastUrl !== '/login') {
        this.router.navigate([lastUrl]);
        sessionStorage.removeItem('lastUrl');
      }
      else if (previousUrl && previousUrl !== '/login' && lastUrl == '/login') {
        this.router.navigate([previousUrl]);
        sessionStorage.removeItem('previousUrl');
      }
      // Otherwise, navigate to the dashboard by default
      else {
        this.router.navigate(['/admin/dashboard']);
      }
    } else {
      this.router.navigate(['/login']);
    }

 
    //else {
    //  this.router.navigate(['/login']);
    //}
  }
  private handleStorageEvent(event: StorageEvent) {
    if (event.key === 'authToken') {
      if (event.newValue) {
        this.authService.setUserDetails();
        this.handleInitialNavigation();
      } else {
          sessionStorage.setItem(`previousUrl`, sessionStorage.getItem(`lastUrl`) || '');
       
        sessionStorage.setItem(`lastUrl`, this.router.url);


        // this.authService.logout();
        this.authService.logoutUserName();
        //setTimeout(() => {
        //  this.router.navigate(['/login']);
        //}, 100);
        this.router.navigate(['/login']); 
      }
    }
  }


}
/*
import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  title = 'app';
  msgs: any;
  mySubscription;

  constructor(private authService: AuthService, private router: Router, private activatedRoute: ActivatedRoute) {
    if (localStorage.getItem('authToken')) {
      this.authService.setUserDetails();
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  public setMessage(severity_value: any, summary_value: any, message_value: any) {
    this.msgs.push({ severity: severity_value, summary: summary_value, detail: message_value });
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }
}
*/
