import { ViewChild, Component, OnInit, ElementRef, Output, EventEmitter, ViewEncapsulation, Renderer2  } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientService } from '../../../services/http.client.service';
import { BackEndUrlService } from '../../../services/backend.url.service';
import { concat } from 'rxjs';
import { error } from 'util';
import { ChatService, ChatMessage } from '../chat.service';
import { ChatGroupService, ChatGroupMessage } from '../chatgroup.service';
import { ScrollToBottomDirective } from '../scroll-to-bottom.directive'
import { promise } from 'protractor';
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from '@angular/common';
import { Browser, getInstance } from '@syncfusion/ej2-base';
import { ContextMenuComponent, MenuEventArgs, MenuItemModel, ContextMenu } from '@syncfusion/ej2-angular-navigations';
import * as moment from 'moment'
import { AuthService } from '../../../services/auth.service';
import { userModel } from '../../../Model/userDetails';
//import { Promise } from 'q';
//import { setTimeout } from 'timers';

//import { setTimeout } from 'timers';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [HttpClientService, BackEndUrlService]
})
export class ChatComponent implements OnInit {
  //@ViewChild(ScrollToBottomDirective, { static: false }) scrollMe: ScrollToBottomDirective;
  @ViewChild('chatSendButton', { static: false }) chatSendButton: ElementRef;
  @ViewChild('chatGroupSendButton', { static: false }) chatGroupSendButton: ElementRef;
  @ViewChild('chatUpdateButton', { static: false }) chatUpdateButton: ElementRef;
  @ViewChild('chatGroupUpdateButton', { static: false }) chatGroupUpdateButton: ElementRef;
  @ViewChild('groupTitleSendButton', { static: false }) groupTitleSendButton: ElementRef;
  @ViewChild('inputGroupmessage', { static: false }) inputGroupmessage: ElementRef;
  @ViewChild('inputMessage', { static: false }) inputMessage: ElementRef;
  @ViewChild('inputGroupTitle', { static: false }) inputGroupTitle: ElementRef;
  @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;
  @ViewChild('inputEditGroupTitle', { static: false }) inputEditGroupTitle: ElementRef;
  @ViewChild('inputGroupSearch', { static: false }) inputGroupSearch: ElementRef;
  @ViewChild('inputHistorySearch', { static: false }) inputHistorySearch: ElementRef;
  @ViewChild('inputChatMessageSearch', { static: false }) inputChatMessageSearch: ElementRef;
  @ViewChild('inputChatGroupMessageSearch', { static: false }) inputChatGroupMessageSearch: ElementRef;
  @ViewChild('chatMessageDiv', { static: false }) chatMessageDiv: ElementRef;
  @ViewChild('inputRecentchatSearch', { static: false }) inputRecentchatSearch: ElementRef;
  @ViewChild('imgRenderer', { static: false }) imgRenderer: ElementRef;
  @ViewChild('imgGroupRenderer', { static: false }) imgGroupRenderer: ElementRef;

  @ViewChild(ContextMenuComponent, { static: false }) contextMenuGroup: ContextMenuComponent;
  @ViewChild(ContextMenuComponent, { static: false }) contextMenuGroupArchive: ContextMenuComponent;
  @ViewChild(ContextMenuComponent, { static: false }) contextMenuMessage: ContextMenuComponent;
  @ViewChild(ContextMenuComponent, { static: false }) contextMenuGroupMessage: ContextMenuComponent;


  public globalMessageId: number = 0;
  public globalRecceivingMessageId: number = -1;
  public globalRecceivingGroupMessageId: number = -1;
  public isLoading: boolean = false;
  public RecentChatList: boolean = false;
  public ContactList: boolean = false;
  public ChatList: boolean = false;
  public ChatHistoryList: boolean = false;
  public ChatImage: boolean = false;

  public ChatMode: boolean = true;
  public chatHistoryFlag: boolean = true;

  public ContactListTitle: string = "Contact List";
  public chatHistoryTitle: string = "";
  public ContactTitle: string = "";

  public chatHistotyMessage: any[] = [];
  public chatGroupHistotyMessage: any[] = [];

  login: any;
  chatUser: ChatUser;

  receiverUser: any[] = [];
  message: string = "";
  sendingMessage: string = "";
  groupmessage: string = "";
  gTitle: string = "";
  recentKeyword: string = "";
  keyword: string = "";
  groupkeyword: string = "";
  chatMessagekeyword: string = ""
  chatGroupMessagekeyword: string = ""
  chatGroup: any;
  selectedGroup: any;
  messageChat: any[] = [];
  editMessageChat: any;
  editGroupMessageChat: any;
  editMode: boolean = false;
  messageChatDate: any[] = [];
  groupTitle: string = "";
  editGroupEdit: boolean = false;
  groupTitleError: boolean = false;
  selectUserError: boolean = false;
  groupChatAddUserMode: boolean = false;
  groupChatArchiveMode: boolean = false;
  groupDetailsArchive: any[] = [];
  searchConversation: string;
  totalUnReadMessageCount: number = -1;
  totalUnReadChatMessageCount: number = -1;
  totalUnReadChatGroupMessageCount: number = -1;
  heightFlag: boolean = true;
  groupAddNew: boolean = false;
  groupAddUserId: number = 0;
  groupAddUserIds: any[] = [];
  selectedUser: any;
  chatGroupMessageMaxId: number = 0;
  activeChatGroupMessageId: number = 0;
  chatMessageMaxId: number = 0;
  activeChatMessageId: number = 0;
  chatMessageLastSeenId: number = 0;
  chatMessageSendingCounter: number = 0;
  chatMessageSearchFlag: boolean = false;
  chatGroupMessageSearchFlag: boolean = false;
  pagingLabel: string = "0/0";
  chatMessageSearchList: any[] = [];
  chatGroupMessageSearchList: any[] = [];
  messageCounter: number = 0;
  public progress: number;
  chatMessageImageMode: boolean = false;
  chatMessageImage: any = null;
  chatGroupMessageImageMode: boolean = false;
  chatGroupMessageImage: any = null;
  margin:number = 0;

  public userData = new userModel();
  userDataSubscription: any;
  //public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  constructor(

    private router: Router,
    private activateRouter: ActivatedRoute,
    private httpService: HttpClientService,
    private backEndUrlService: BackEndUrlService,
    private cookieService: CookieService,
    
    private chatGroupService: ChatGroupService,
    private datePipe: DatePipe,
    private chatService: ChatService,
    private http: HttpClient,
    private elementRef: ElementRef,
    private domSanitizer: DomSanitizer,
    private _r: Renderer2,
    private authService: AuthService
  ) {
    this.authService.setUserDetails();
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      //console.log("this.userData ", this.userData)
    });
  }
  ngOnInit() {
    //this.login = JSON.parse(localStorage.getItem("UserInfo"));
    this.start();

    this._r.listen(document, 'keydown', (event) => {
      if (this.ChatList) {
        if (this.ChatMode) {
          if (this.message.substr(this.message.length - 1, 1) == '#') {
            this.inputMessage.nativeElement.click();
            if (!(typeof this.inputMessage === "undefined")) {
              this.inputMessage.nativeElement.focus();
            }
          }
        }
        else {
          if (this.groupmessage.substr(this.groupmessage.length - 1, 1) == '#') {
            this.inputGroupmessage.nativeElement.click();
            if (!(typeof this.inputGroupmessage === "undefined")) {
              this.inputGroupmessage.nativeElement.focus();
            }
          }
        }
      }
      
    })
    if (window.document.body.offsetHeight > window.innerHeight) { this.margin = 15; }
  }
  
  start() {
    setTimeout(() => {
      
      if (this.chatService.connectionIsEstablished && this.chatGroupService.connectionIsEstablished) {
        setTimeout(() => {
          this.ChatImage = true;
        }, 2000);
      }
      else {
        this.start();
      }
    }, 500);
  }
  async reset() {
    this.searchConversation = "";
    this.receiverUser = [];
    this.selectedGroup = [];
    this.chatHistotyMessage = [];
    if (this.editMode) {
      this.messageUpdateCancel();
    }

    this.heightFlag = true;
    this.editGroupEdit = false;
    this.groupAddUserId = 0;
    this.groupAddUserIds = [];
    this.chatMessageSendingCounter = 0;
    this.chatGroupMessageMaxId = 0;
    this.chatMessageMaxId = 0;
    this.chatMessageLastSeenId = 0
    this.ContactList = false;
    this.ChatList = false;
    this.ChatImage = false;
    this.RecentChatList = false;
    this.groupChatAddUserMode = false

    this.pagingLabel = "0/0";
    this.chatMessageSearchList = [];
    this.chatGroupMessageSearchList= [];
    this.messageCounter = 0;
  }
  clearActiveWindow() {
    this.chatService.ClearActiveWindow();
    this.chatGroupService.ClearActiveGroupWindow();
  }
  async chatImageClick() {
    this.reset();
    this.clearActiveWindow();
    this.RecentChatList = true;
    this.ContactList = false;
    this.ChatList = false;
    this.ChatImage = false;
    this.groupChatArchiveMode = false;
    //this.inputSearch.nativeElement.focus();
    setTimeout(() => {
      if (!(typeof this.inputRecentchatSearch === "undefined")) {
        this.inputRecentchatSearch.nativeElement.focus();
      }
    }, 100);
  }

  chatPopUpCloseClick(): void {
    this.reset();
    this.clearActiveWindow();
    this.ContactList = false;
    this.ChatList = false;
    this.ChatHistoryList = false;
    this.ChatImage = true;
    this.ChatMode = true;
  }
  async chatContactListClick(contactMode: string) {
    this.reset();
    this.clearActiveWindow();
    this.groupChatArchiveMode = false;
    this.ContactList = true;
    this.ChatList = false;
    this.ChatHistoryList = false;
    this.ChatImage = false;
    this.groupTitle = "";
    this.groupChatAddUserMode = false;
    this.recentKeyword = '';
    if (contactMode == 'Contact List') {
      this.keyword = '';
      this.messageChat = [];
      this.ContactListTitle = 'Contact List';
      this.chatMessageSearchFlag = false;
      this.chatMessagekeyword = '';
      this.ChatMode = true;
      setTimeout(() => {
        if (!(typeof this.inputSearch === "undefined")) {
          this.inputSearch.nativeElement.focus();
        }
      }, 100);
    }
    else {
      this.groupkeyword = '';
      this.ContactListTitle = 'Group List';
      this.chatGroupMessageSearchFlag = false;
      this.chatGroupMessagekeyword = '';
      this.ChatMode = false;
      setTimeout(() => {
        if (!(typeof this.inputGroupSearch === "undefined")) {
          this.inputGroupSearch.nativeElement.focus();
        }
      }, 100);
    }

  }
  async ChatUserClick(receiver: any) {
    this.reset();
    this.globalRecceivingMessageId = -1;
    if (receiver.isLogged) {
      receiver.loggedColor = {
        'border': "0px solid #33A532",
        'border-radius': "50%"
      };
      receiver.loggedCss = 'dot-logged';
    }
    else {
      receiver.loggedColor = {
        'border': "0px solid red",
        'border-radius': "50%"
      };
      receiver.loggedCss = 'dot';
    }
    this.receiverUser = [];
    this.receiverUser.push(receiver);
    //console.log("receiverUser ", this.receiverUser);
    this.ContactList = false;
    this.ChatList = true;
    this.ChatImage = false;
    this.ChatHistoryList = false;
    this.ChatMode = true;
    this.chatService.SetActiveWindow(this.receiverUser);
    this.chatService.getChatMessage(this.receiverUser);
    this.ContactTitle =  receiver.userName ;
    this.globalMessageId = 0;
    this.messageChatDate = [];
    this.setChatMessageRead();
    //this.getChatMessage();
    this.message = "";
    //his.inputMessage.nativeElement.focus();
    if (this.recentSearchChatMessageExist) {
      if (this.recentKeyword != "") {
        this.chatMessagekeyword = this.recentKeyword;
        this.chatMessageSearchFlag = true;
        setTimeout(() => {
          this.chatMessageSearch();
        }, 2000);

      }
    }
    setTimeout(() => {
      if (!(typeof this.inputMessage === "undefined")) {
        this.inputMessage.nativeElement.focus();
      }
    }, 100);

  }
  recetChatGroupClick(item: any ): void {
    this.ChatMode = false;
    this.groupAddUserId = 0;
    
    if (this.recentSearchChatGroupMessageExist) {
      if (this.recentKeyword != '') {
        this.chatGroupMessageSearchFlag = true;
        this.chatGroupMessagekeyword = this.recentKeyword;
        setTimeout(() => {
          this.chatGroupMessageSearch();
        }, 2000);
      }
      else {
        this.chatGroupMessageSearchFlag = false;
        this.chatGroupMessagekeyword = '';
      }
    }
    else {
      this.chatGroupMessageSearchFlag = false;
      this.chatGroupMessagekeyword = '';
    }
    this.chatGroupClick(item);
  }
  chatGroupClick(item: any): void {
    if (this.groupAddUserId == 0) {
     
            this.reset();
      this.selectedGroup = item;
      this.chatGroupService.SetActiveGroupWindow(item.chatGroupId);
      this.chatGroupService.GetChatGroupMessageByChatGroupId(item.chatGroupId);
      this.chatGroupService.SetChatGroupMessageSeen(item.chatGroupId);

      this.ContactList = false;
      this.ChatList = true;
      this.ChatImage = false;
      this.ChatHistoryList = false;
      this.ContactTitle = item.groupTitle;
      this.groupmessage = "";
      this.globalMessageId = 0;
      this.globalRecceivingGroupMessageId = -1;
      setTimeout(() => {
        if (!(typeof this.inputGroupmessage === "undefined")) {
          this.inputGroupmessage.nativeElement.focus();
        }
      }, 100);
    }
    else {
      this.groupTitle = this.groupTitle.trim();
      if (this.groupTitle != '') {

        this.ChatGroupCreate();
      }
    }
  }
  GroupCreateClick(item: any) {
    //console.log("new",item);
    this.reset();
    this.groupAddNew = true;
    this.groupAddUserId = -1;
    if (item != null) {
      this.groupAddUserId = item.userID;
      this.groupAddUserIds.push(item.userID);
    }
    this.ContactList = true;
    this.ChatList = false;
    this.ChatImage = false;
    this.ChatHistoryList = false;
    this.ChatMode = false;
    this.ContactTitle = "";
    this.ContactListTitle = 'Group List';
    this.groupmessage = "";
    this.groupChatAddUserMode = true;
    this.selectedGroup = [];
    setTimeout(() => {
      if (!(typeof this.inputGroupTitle === "undefined")) {
        this.inputGroupTitle.nativeElement.focus();
      }
    }, 100);

  }
  chatChatHistoryClick(mode: string): void {
    this.searchConversation = "";
    this.chatHistotyMessage = [];
    this.chatGroupHistotyMessage = [];
    if (this.editMode) {
      this.messageUpdateCancel();
    }
    this.chatService.ClearActiveWindow();
    this.chatGroupService.ClearActiveGroupWindow();

    this.ContactList = false;
    this.ChatList = false;
    this.ChatHistoryList = true;
    this.ChatImage = false;
    this.chatHistoryTitle = mode;
    if (mode == 'Chat History') {
      this.chatHistoryFlag = true;
      if (!(typeof this.receiverUser === "undefined")) {
        if (this.receiverUser.length != 0) {
          //console.log("receiverUser,", this.receiverUser);
          this.chatHistoryTitle = this.chatHistoryTitle + ' ( ' + this.receiverUser[0].userName + ' ) ';
        }
      }
      if (!(typeof this.selectedGroup === "undefined")) {
        if (this.selectedGroup.length != 0) {
          //console.log("selectedGroup", this.selectedGroup);
          this.chatHistoryTitle = this.chatHistoryTitle + ' ( ' + this.selectedGroup.groupTitle + ' ) ';
        }
      }
    }
    else {
      this.chatHistoryFlag = false;
    }
    setTimeout(() => {
      if (!(typeof this.inputHistorySearch === "undefined")) {
        this.inputHistorySearch.nativeElement.focus();
      }
    }, 100);
  }
  async setChatMessageRead() {

    
      if (!(typeof this.chatService.messageUnreadCount === "undefined")) {
        var unseen = <any>this.chatService.messageUnreadCount.filter(x => ((x.receiverId == this.userData.userId) && (x.senderId == this.receiverUser[0].userID)));
        
        if (unseen.length != 0) {
          if (unseen[0].unReadMessageCount != 0) {
            
            await this.chatService.setChatMessageRead(this.receiverUser);
            this.chatService.getChatMessageUnreadCount();
          }

        }
        
      
    }
  }
  chatMessageSearchShow() {
    

    this.chatMessageSearchFlag = !this.chatMessageSearchFlag;
    if (this.chatMessageSearchFlag) {
      setTimeout(() => {
        if (!(typeof this.inputChatMessageSearch === "undefined")) {
          this.inputChatMessageSearch.nativeElement.focus();
        }
      }, 100);
    }
    else {
      this.chatMessagekeyword = "";
      setTimeout(() => {
        if (!(typeof this.inputMessage === "undefined")) {
          this.inputMessage.nativeElement.focus();
        }
      }, 100);
    }
  }
  chatGroupMessageSearchShow() {
    

    this.chatGroupMessageSearchFlag = !this.chatGroupMessageSearchFlag;
    
    if (this.chatGroupMessageSearchFlag) {
      setTimeout(() => {
        //console.log(!(typeof this.inputChatGroupMessageSearch === "undefined"));
        if (!(typeof this.inputChatGroupMessageSearch === "undefined")) {
    
          this.inputChatGroupMessageSearch.nativeElement.focus();
        }
      }, 100);
    }
    else {
      this.chatGroupMessagekeyword = "";
      setTimeout(() => {
        if (!(typeof this.inputGroupmessage === "undefined")) {
          this.inputGroupmessage.nativeElement.focus();
        }
      }, 100);
    }
  }
  closeGroupCreateWindow() {
    if (this.groupAddUserId > 0) {
      this.chatContactListClick('Contact List');
    } else {
      this.chatContactListClick('Group List');
    }
  }
  chatMessageUserIds: any[] = [];
  chatGroupIds: any[] = [];
  searchRecentList() {
    this.chatMessageUserIds = [];
    this.chatGroupIds = [];
    this.httpService.get('api/Chat/GetChatMessageRecentListByKeyword/' + this.recentKeyword + '/' + this.userData.userId)
  .subscribe(
    data => {
      this.chatMessageUserIds = <any[]>data;
    
    },
    error => {
    
    }
    );
    this.httpService.get('api/Chat/GetChatGroupMessageRecentListByKeyword/' + this.recentKeyword + '/' + this.userData.userId)
      .subscribe(
        data => {
          this.chatGroupIds = <any[]>data;
    
        },
        error => {
    
        }
      );
  }
  recentSearchChatMessageExist: boolean = false;
  recentSearchChatGroupMessageExist: boolean = false;
  getRecentChatList(): any[] {
    this.recentSearchChatMessageExist = false;
    this.recentSearchChatGroupMessageExist = false;
    var recentList: any[] = [];
    if (!(typeof this.chatService.chatUsers === "undefined")) {
    
      var users :any[] = this.chatService.chatUsers.filter(x => x.userID != this.userData.userId).filter(it => it.userName.toLowerCase().includes(this.recentKeyword.toLowerCase()));
      if (!(typeof this.chatMessageUserIds === "undefined")) {
        if (this.chatMessageUserIds.length > 0) {
          for (let n = 0; n < this.chatMessageUserIds.length; ++n) {
            if (users.filter(x => (x.userID == this.chatMessageUserIds[n].userId)).length == 0) {
              if (this.chatService.chatUsers.filter(x => x.userID == this.chatMessageUserIds[n].userId).length > 0) {
    
                users.push(<any>this.chatService.chatUsers.filter(x => x.userID == this.chatMessageUserIds[n].userId)[0]);
                this.recentSearchChatMessageExist = true;
              }
            }
          }
        }
      }
    
      
          
          for (let n = 0; n < users.length; ++n) {
    
            users[n].cdate = 0;
            if (!(typeof this.chatService.messageUnreadCount === "undefined")) {
              if (this.chatService.messageUnreadCount.length > 0) {
                var receiverUsers = this.chatService.messageUnreadCount.filter(x => (x.receiverId == this.userData.userId));
                var msgcount = receiverUsers.filter(x => (x.senderId == users[n].userID));
                
                if (msgcount.length > 0) {
                  users[n].unReadMessage = msgcount[0].unReadMessageCount;
                }
                else {
                  users[n].unReadMessage = "";
                }
              }
            }
            else { users[n].unReadMessage = "";}
            if (users[n].isLogged) {
              users[n].loggedColor = {
                border: "1px solid #33A532",
                borderRadius: "50%",

              };
              users[n].loggedCss = 'dot-logged';
            }
            else {
              users[n].loggedColor = {
                border: "1px solid red",
                borderRadius: "50%",

              };
              users[n].loggedCss = 'dot';
            }
            users[n].userFlag = true;
            users[n].message = "";
            users[n].createdDate = "";
            if (!(typeof this.chatService.lastMessageAll === "undefined")) {
              if (this.chatService.lastMessageAll.length > 0) {
                var lmessage = this.chatService.lastMessageAll.filter(x => (
                  ((x.senderId == this.userData.userId) && (x.receiverId == users[n].userID))
                  || ((x.receiverId == this.userData.userId) && (x.senderId == users[n].userID))
                ))
                  .sort(function (a, b) {
                    return b.chatMessageId - a.chatMessageId;
                  });
                if (lmessage.length > 0) {
                  users[n].message = lmessage[0].message;
                  users[n].cdate = new Date(lmessage[0].createdDate).getTime();
                  recentList.push(users[n])
                }
                
              }
            }
    
          }
        
      
    
      

    }

    if (!(typeof this.chatGroupService.chatGroupDetails === "undefined")) {
      
      var groupDetails = this.chatGroupService.chatGroupDetails.filter(x => (x.userId == this.userData.userId))
      
      for (let n = 0; n < groupDetails.length; n++) {
        var i = groupDetails[n];

        if (!(typeof this.chatGroupService.groupMessageUnreadCount === "undefined")) {

          var item = this.chatGroupService.groupMessageUnreadCount.filter(x =>
            ((x.chatGroupId == groupDetails[n].chatGroupId) && (x.userId == this.userData.userId)));
          if (item.length == 0) { i.unReadMessage = ''; }
          else if (item[0].unReadMessageCount == 0) { i.unReadMessage = ''; }
          else { i.unReadMessage = item[0].unReadMessageCount; }
        }
        else { i.unReadMessage = ''; }

        i.receiverNames = this.getGroupChatReceiverNames(i.chatGroupId);
        i.userFlag = false;
        i.message = "";
        i.createdDate = "";
        i.cdate = 0;
        if (!(typeof this.chatGroupService.lastChatGroupMessage === "undefined")) {
          if (this.chatGroupService.lastChatGroupMessage.length > 0) {
            var lmessage = this.chatGroupService.lastChatGroupMessage.filter(x => (
              x.chatGroupId == i.chatGroupId) )
              .sort(function (a, b) {
                return b.chatGroupMessageId - a.chatGroupMessageId;
              });
            if (lmessage.length > 0) {
              i.message = lmessage[0].message;
              i.cdate = new Date(lmessage[0].createdDate).getTime();
            }

          
        if (this.recentKeyword != '') {
          if (this.chatGroupIds.filter(x => (x.chatGroupId == groupDetails[n].chatGroupId)).length > 0) {
            recentList.push(i);
            this.recentSearchChatGroupMessageExist = true;
          }
          else {
            var f = this.chatGroupService.chatGroupDetails.filter(x => x.userName.toLowerCase().includes(this.recentKeyword.toLowerCase()));
            f = f.filter(x => x.chatGroupId == groupDetails[n].chatGroupId);
            if (f.length != 0) {
              recentList.push(i);
            }
            else {
              if (groupDetails[n].groupTitle.toLowerCase().includes(this.recentKeyword.toLowerCase())) {
                recentList.push(i);
              }
            }
          }
        }
        else {
          //if (groupDetails[n].groupTitle.toLowerCase().includes(this.groupkeyword.toLowerCase())) {
          recentList.push(i);
          // }
            }
          }
        }
      
      }
      
    }
    
    return recentList.sort(function (a, b) {
    
      return b.cdate - a.cdate;
    });
  }
  getChatUsers(): any[] {
    if (!(typeof this.chatService.chatUsers === "undefined")) {
    
      var users = this.chatService.chatUsers.filter(x => x.userID != this.userData.userId);
      if (!(typeof this.chatService.messageUnreadCount === "undefined")) {
        if (this.chatService.messageUnreadCount.length > 0) {
          var receiverUsers = this.chatService.messageUnreadCount.filter(x => (x.receiverId == this.userData.userId))
          for (let n = 0; n < users.length; ++n) {
    
            var msgcount = receiverUsers.filter(x => (x.senderId == users[n].userID));
    
            if (msgcount.length > 0) {
              users[n].unReadMessageCount = msgcount[0].unReadMessageCount;
            }
            else {
              users[n].unReadMessageCount = "";
            }
            if (users[n].isLogged) {
              users[n].loggedColor = {
                'border': "0px solid #33A532",
                'border-radius': "50%"
              };
              users[n].loggedCss = 'dot-logged';
            }
            else {
              users[n].loggedColor = {
                'border': "0px solid red",
                'border-radius': "50%"
              };
              users[n].loggedCss = 'dot';
            }
          }
        }
      }
    
      return users.filter(it => it.userName.toLowerCase().includes(this.keyword.toLowerCase())).sort(function (a, b) {
        return b.isLogged - a.isLogged || b.unReadMessageCount - a.unReadMessageCount;
      });

    }
  }
  userBgColor(item: any): string {
    
    var clrCode = '#0' + item.senderId + '9';

    var i = this.chatService.chatUsers.filter(x => x.userID == item.senderId);
    if (i.length != 0) {
      clrCode = '#' + i[0].colorCode;
    }
    
    return clrCode;
  }
  messageAllLocal: any[] = []
  chatMessageKeywordTrack: boolean = true;
  chatMessageSearchEvent($event: any) { 
    
    if (($event.shiftKey) && ($event.key == 'Enter')) {
      this.chatSerachDown();
    }
    else {
      if (($event.key != 'Enter') && ($event.key != 'Shift')) {
        this.chatMessageSearch()
      }
    }
  }
  chatMessageSearch() {
    this.chatMessageKeywordTrack = true;
        this.chatMessageSearchList = [];
        this.pagingLabel = "0/0";
        this.messageCounter = 0;
        if (this.chatMessagekeyword != "") {
          if (!(typeof this.chatService.messageAll === "undefined")) {
            this.messageAllLocal = this.chatService.messageAll.filter(x => (
              ((x.senderId == this.receiverUser[0].userID) && (x.receiverId == this.userData.userId)) ||
              ((x.receiverId == this.receiverUser[0].userID) && (x.senderId == this.userData.userId))
            ));

            this.chatMessageSearchList = this.messageAllLocal.filter(x => x.message.toLowerCase().includes(this.chatMessagekeyword.toLowerCase())).sort(function (a, b) {
              return b.chatMessageId - a.chatMessageId;
            });
            if (this.chatMessageSearchList.length == 0) {
              this.pagingLabel = "0/0";
            }
            else {
              this.pagingLabel = "1/" + this.chatMessageSearchList.length;
              this.activeChatMessageId = this.chatMessageSearchList[this.messageCounter].chatMessageId;
              let HTMLElementObj: HTMLDivElement = document.getElementById("divChatMessage" + this.chatMessageSearchList[this.messageCounter].chatMessageId) as HTMLDivElement;
              let HTMLDivElementObj: HTMLDivElement = document.getElementById("chatMessageDiv") as HTMLDivElement;
              HTMLDivElementObj.scrollTop = HTMLElementObj.offsetTop - HTMLDivElementObj.offsetTop;
            }


            for (let n = 0; n < this.messageAllLocal.length; ++n) {

              if (this.chatMessagekeyword != this.chatMessagekeywordOld) {
                if ((this.chatMessagekeyword != "") && (this.chatMessageSearchList.length > 0)) {
                  this.messageAllLocal[n].updateMessage = this.updateChatMesageForSearch(this.messageAllLocal[n].chatMessageId, this.messageAllLocal[n].message);

                  if (this.globalMessageId == this.messageAllLocal[n].chatMessageId) {
                    this.chatMessagekeywordOld = this.chatMessagekeyword;
                  }
                }

              }
            }
            //console.log(this.messageAllLocal);
          }
        }
      
    
  }
  chetMessageSearchMove($event: any) {
    
  }
  chatSerachDown() {

    if (this.messageCounter > 0) {
      this.messageCounter = this.messageCounter - 1;
      this.activeChatMessageId = this.chatMessageSearchList[this.messageCounter].chatMessageId;
    
      let HTMLElementObj: HTMLDivElement = document.getElementById("divChatMessage" + this.chatMessageSearchList[this.messageCounter].chatMessageId) as HTMLDivElement;
      let HTMLDivElementObj: HTMLDivElement = document.getElementById("chatMessageDiv") as HTMLDivElement;
      HTMLDivElementObj.scrollTop = HTMLElementObj.offsetTop - HTMLDivElementObj.offsetTop;
      this.pagingLabel = (this.messageCounter + 1) + "/" + this.chatMessageSearchList.length;
    }
  }
  chatSerachUP() {
    //console.log("chatSerachUP" + this.messageCounter);
    if (this.messageCounter < this.chatMessageSearchList.length) {
      this.messageCounter = this.messageCounter + 1;
      this.activeChatMessageId = this.chatMessageSearchList[this.messageCounter].chatMessageId;
    
      let HTMLElementObj: HTMLDivElement = document.getElementById("divChatMessage" + this.chatMessageSearchList[this.messageCounter].chatMessageId) as HTMLDivElement;
      let HTMLDivElementObj: HTMLDivElement = document.getElementById("chatMessageDiv") as HTMLDivElement;
      HTMLDivElementObj.scrollTop = HTMLElementObj.offsetTop - HTMLDivElementObj.offsetTop;
      this.pagingLabel = (this.messageCounter + 1) + "/" + this.chatMessageSearchList.length;
    }
  }
  
  chatGroupMessagesLocal: any[] = [];
  chatGroupMessagekeywordOld: string = '';
  chatGroupMessageKeywordTrack: boolean = true;
  chatGroupMessageSearchEvent($event: any) {
    //console.log("chatGroupMessageSearchEvent ",$event);
    if (($event.shiftKey) && ($event.key == 'Enter')) {
      this.chatGroupSerachDown();
    }
    else {
      if (($event.key != 'Enter') && ($event.key != 'Shift') ){
        this.chatGroupMessageSearch()
      }
    }
  }
  chatGroupMessageSearch() {
    
        this.chatGroupMessageKeywordTrack = true;
        this.chatGroupMessageSearchList = [];
        this.pagingLabel = "0/0";
        this.messageCounter = 0;
        if (this.chatGroupMessagekeyword != "") {
    
          if (!(typeof this.chatGroupService.chatGroupMessages === "undefined")) {

            this.chatGroupMessagesLocal = this.chatGroupService.chatGroupMessages.filter(x => x.chatGroupId == this.selectedGroup.chatGroupId);
            this.chatGroupMessageSearchList = this.chatGroupMessagesLocal.filter(x => x.message.toLowerCase().includes(this.chatGroupMessagekeyword.toLowerCase())).sort(function (a, b) {
              return b.chatGroupMessageId - a.chatGroupMessageId;
            });
            if (this.chatGroupMessageSearchList.length == 0) {
              this.pagingLabel = "0/0";
            }
            else {
              this.pagingLabel = "1/" + this.chatGroupMessageSearchList.length;
              this.activeChatGroupMessageId = this.chatGroupMessageSearchList[this.messageCounter].chatGroupMessageId;
              
              let HTMLElementObj: HTMLDivElement = document.getElementById("divChatGroupMessage" + this.chatGroupMessageSearchList[this.messageCounter].chatGroupMessageId) as HTMLDivElement;
              let HTMLDivElementObj: HTMLDivElement = document.getElementById("chatGroupMessageDiv") as HTMLDivElement;
              HTMLDivElementObj.scrollTop = HTMLElementObj.offsetTop - HTMLDivElementObj.offsetTop;
            }
          }
          

          for (let n = 0; n < this.chatGroupMessagesLocal.length; ++n) {

            if (this.chatGroupMessagekeyword != this.chatGroupMessagekeywordOld) {
              if ((this.chatGroupMessagekeyword != "") && (this.chatGroupMessageSearchList.length > 0)) {
                this.chatGroupMessagesLocal[n].updateMessage = this.updateChatGroupMesageForSearch(this.chatGroupMessagesLocal[n].chatGroupMessageId, this.chatGroupMessagesLocal[n].message);

                if (this.globalMessageId == this.chatGroupMessagesLocal[n].chatGroupMessageId) {
                  this.chatGroupMessagekeywordOld = this.chatGroupMessagekeyword;
                }
              }

            }
          }
        }
      
  }
  chetGroupMessageSearchMove($event: any) {
    //console.log("chetGroupMessageSearchMove ",$event);
    
      this.chatGroupSerachUP();
    
  }
  chatGroupSerachDown() {

    if (this.messageCounter > 0) {
      
      this.messageCounter = this.messageCounter - 1;
      this.activeChatGroupMessageId = this.chatGroupMessageSearchList[this.messageCounter].chatGroupMessageId;
      
      let HTMLElementObj: HTMLDivElement = document.getElementById("divChatGroupMessage" + this.chatGroupMessageSearchList[this.messageCounter].chatGroupMessageId) as HTMLDivElement;
      let HTMLDivElementObj: HTMLDivElement = document.getElementById("chatGroupMessageDiv") as HTMLDivElement;
      HTMLDivElementObj.scrollTop = HTMLElementObj.offsetTop - HTMLDivElementObj.offsetTop;
      this.pagingLabel = (this.messageCounter + 1) + "/" + this.chatGroupMessageSearchList.length;
    }
  }
  chatGroupSerachUP() {
    //console.log("chatSerachUP" + this.messageCounter);
    if (this.messageCounter < this.chatGroupMessageSearchList.length-1) {
      this.messageCounter = this.messageCounter + 1;
      this.activeChatGroupMessageId = this.chatGroupMessageSearchList[this.messageCounter].chatGroupMessageId;
      //console.log("chatSerachUP" + this.messageCounter);
      let HTMLElementObj: HTMLDivElement = document.getElementById("divChatGroupMessage" + this.chatGroupMessageSearchList[this.messageCounter].chatGroupMessageId) as HTMLDivElement;
      let HTMLDivElementObj: HTMLDivElement = document.getElementById("chatGroupMessageDiv") as HTMLDivElement;
      HTMLDivElementObj.scrollTop = HTMLElementObj.offsetTop - HTMLDivElementObj.offsetTop;
      this.pagingLabel = (this.messageCounter + 1) + "/" + this.chatGroupMessageSearchList.length;
    }
  }
  async getChatMessage() {

    this.messageChatDate = [];
    this.messageChatDate = this.chatService.messageAll.map(item => item.createdDateOnly)
      .filter((value, index, self) => self.indexOf(value) === index);
    //console.log('results', this.messageChatDate);

  }

  getChatMessageDate() {
    if (!(typeof this.chatService.messageAll === "undefined")) {
      this.messageAllLocal = this.chatService.messageAll.filter(x => (
        ((x.senderId == this.receiverUser[0].userID) && (x.receiverId == this.userData.userId)) ||
        ((x.receiverId == this.receiverUser[0].userID) && (x.senderId == this.userData.userId))
      ));
      this.messageChatDate = this.messageAllLocal.map(item => item.createdDateOnly)
        .filter((value, index, self) => self.indexOf(value) === index);

    }
    //console.log(this.messageChatDate);
    return this.messageChatDate;

  }
  updateChatGroupMesageForSearch(chatGroupMessageId: number, msg: string) {
    if (this.chatGroupMessagekeyword.trim() != '') {
      if (msg.toLowerCase().includes(this.chatMessagekeyword.toLowerCase())) {
        var regEx = new RegExp(this.chatGroupMessagekeyword.trim(), "ig");
        if ((this.messageCounter >= 0) && (this.messageCounter < this.chatGroupMessageSearchList.length)) {
          if (this.chatGroupMessageSearchList[this.messageCounter].chatGroupMessageId == chatGroupMessageId) {
            msg = msg.replace(regEx, '<mark>' + this.chatGroupMessagekeyword.trim() + '</mark>');
          }
          else {
            msg = msg.replace(regEx, '<mark>' + this.chatGroupMessagekeyword.trim().toLowerCase() + '</mark>');
          }
        }
        else {
          msg = msg.replace(regEx,  '<mark>' + this.chatGroupMessagekeyword.trim() + '</mark>');
        }
      }
    }
    msg = msg.replace('\n', '<br>');
    msg = this.mesasgeUpdateForLink(0, msg, this.chatGroupMessagekeyword.trim());
    msg = this.mesasgeUpdateForLink(1, msg, this.chatGroupMessagekeyword.trim());
    msg = this.mesasgeUpdateForLink(2, msg, this.chatGroupMessagekeyword.trim());
    msg = this.mesasgeUpdateForUrlLink(0, msg, this.chatGroupMessagekeyword.trim());
    msg = this.mesasgeUpdateForUrlLink(1, msg, this.chatGroupMessagekeyword.trim());
    msg = this.mesasgeUpdateForUrlLink(2, msg, this.chatGroupMessagekeyword.trim());
    


    return this.domSanitizer.bypassSecurityTrustHtml( msg);
  }
  
  mesasgeUpdateForLink(flag: number, inputMessage: string, searchText:string):string {
    var Keyword: string = "";
    var link: string = "";
    if (flag == 0) {
      Keyword = '#SKU';
      link = '/admin/catalog-tool/product/DisplayEditProduct/';
    }
    if (flag == 1) {
      Keyword = '#OrderId';
      link = '/admin/orders/order-details/';
    }
    if (flag == 2) {
      Keyword = '#Ticket';
      link = '/admin/task/view/';
    }
    if (searchText.trim() != '') {
      if (Keyword.toLowerCase().includes(searchText.toLowerCase())) {
        var regEx = new RegExp(searchText.trim(), "ig");
        Keyword = Keyword.replace(regEx, '<mark>' + searchText + '</mark>');
      }
    }
    let msg: string = "";
    if (inputMessage.indexOf(Keyword) > -1) {
      var arr = inputMessage.split(Keyword);
      msg = arr[0];
      
      for (let n = 1; n < arr.length; ++n) {
        msg = msg + Keyword;
        let temp: string = arr[n];
        if (temp != '') {
          let tempArr: any[] = temp.split(" ");
          let str: string = "";
          let idflag: boolean = false;
          for (let m = 0; m < tempArr.length; ++m) {
            if (!idflag) {
              if (tempArr[m] == '') { msg = msg + ' '; }
              else {
                let id: string = tempArr[m];
                let linkId: string = id;
                linkId = linkId.replace('<mark>', '').replace('</mark>', '');
                if (flag == 2) {
                  linkId = linkId.replace(/[sSmMuUrR]/g, '');
                }
                msg = msg + '<a class="link" target="_blank" href="'+link + linkId+'" title="'+Keyword+'" >' + id + '</a>';
                idflag = true;
              }
            }
            else {
              str = str + ' ' + tempArr[m];
            }
          }
          msg = msg + str;
        }
      }
    }
    else { msg = inputMessage; }
    return msg;
  }
  mesasgeUpdateForUrlLink(flag: number, inputMessage: string, searchText: string): string {
    var Keyword: string = "";
    var link: string = "";
    if (flag == 0) {
      Keyword = 'https:';
      
    }
    if (flag == 1) {
      Keyword = 'http:';
    }
    if (flag == 2) {
      Keyword = 'www.';
    }
    if (searchText.trim() != '') {
      if (Keyword.toLowerCase().includes(searchText.toLowerCase())) {
        var regEx = new RegExp(searchText.trim(), "ig");
        Keyword = Keyword.replace(regEx, '<mark>' + searchText + '</mark>');
      }
    }
    let msg: string = "";
    if (inputMessage.indexOf(Keyword) > -1) {
      var arr = inputMessage.split(Keyword);
      msg = arr[0];

      for (let n = 1; n < arr.length; ++n) {
        link = Keyword.replace('<mark>', '').replace('</mark>', '')
        let temp: string = arr[n];
        if (temp != '') {
          let tempArr: any[] = temp.split(" ");

          let str: string = "";
          let idflag: boolean = false;
          for (let m = 0; m < tempArr.length; ++m) {
            if (!idflag) {
              if (tempArr[m] == '') { msg = msg + ' '; }
              else {
                let id: string = tempArr[m];
                let linkUrl: string = id;
                let linkText: string =link+ id;
                //console.log(m, linkUrl);
                linkUrl = link + linkUrl.replace('<mark>', '').replace('</mark>', '');
                //console.log(m, linkUrl);
                if (flag == 0) {
                  linkUrl = linkUrl.replace('https://www.', 'https://');
                  linkText = linkText.replace('https://www.', 'https://');
                }
                if (flag == 1) {
                  linkUrl = linkUrl.replace('http://www.', 'http://');
                  linkText = linkText.replace('http://www.', 'http://');
                }
                if (flag == 2) {
                  linkUrl = linkUrl.replace('www.', '');
                  linkText = linkText.replace('www.', '');
                }
                if (flag == 2) {
                  msg = msg + '<a class="link" target="_blank" href="http://www.' + linkUrl + '" title="' + linkUrl + '" >' + linkText + '</a>';
                }
                else {
                  msg = msg + '<a class="link" target="_blank" href="' + linkUrl + '" title="' + linkUrl + '" >' + linkText + '</a>';
                }
                idflag = true;
              }
            }
            else {
              str = str + ' ' + tempArr[m];
            }
          }
          msg = msg + str;
        }
      }
    }
    else { msg = inputMessage; }
    return msg;
  }
  updateChatMesageForSearch(chatMessageId: number, msg: string) {
    let flag: boolean = false;
    if (this.chatMessagekeyword.trim() != '') {
      if (msg.toLowerCase().includes(this.chatMessagekeyword.toLowerCase())) {
        var regEx = new RegExp(this.chatMessagekeyword.trim(), "ig");
        if ((this.messageCounter >= 0) && (this.messageCounter < this.chatMessageSearchList.length)) {
          
          if (this.chatMessageSearchList[this.messageCounter].chatMessageId == chatMessageId) {
            flag = true;
            msg = msg.replace(regEx, '<mark>' + this.chatMessagekeyword.trim() + '</mark>');
          }
          else {
            msg = msg.replace(regEx, '<mark>' + this.chatMessagekeyword.trim() + '</mark>');
          }
        }
        else {
          msg = msg.replace(regEx, '<mark>' + this.chatMessagekeyword.trim() + '</mark>');
        }
      }
    }
    msg = msg.replace('\n', '<br>');
    msg = this.mesasgeUpdateForLink(0, msg, this.chatMessagekeyword.trim());
    msg = this.mesasgeUpdateForLink(1, msg, this.chatMessagekeyword.trim() );
    msg = this.mesasgeUpdateForLink(2, msg, this.chatMessagekeyword.trim() );
    msg = this.mesasgeUpdateForUrlLink(0, msg, this.chatGroupMessagekeyword.trim());
    msg = this.mesasgeUpdateForUrlLink(1, msg, this.chatGroupMessagekeyword.trim());
    msg = this.mesasgeUpdateForUrlLink(2, msg, this.chatGroupMessagekeyword.trim());
    return this.domSanitizer.bypassSecurityTrustHtml(msg);
  }
  chatMessagekeywordOld: string = "";
  getChatMessageByDate(dt: string): any[] {
    this.globalMessageId = Math.max.apply(Math, this.chatService.messageAll.filter(x => (
      ((x.senderId == this.receiverUser[0].userID) && (x.receiverId == this.userData.userId)) ||
      ((x.receiverId == this.receiverUser[0].userID) && (x.senderId == this.userData.userId))
    )).map(function (o) { return o.chatMessageId; }));

    let chatMessage:any[] = this.messageAllLocal.filter(x => x.createdDateOnly == dt);
    
    for (let n = 0; n < chatMessage.length; ++n) {
      if ((chatMessage[n].updateMessage == '') ) {
          chatMessage[n].updateMessage = this.updateChatMesageForSearch(chatMessage[n].chatMessageId, chatMessage[n].message);
      }
    }
    
    return chatMessage.sort(function (a, b) {
      return a.chatMessageId - b.chatMessageId;
    });
  }
  chatGroupEditCancel() {
    this.groupTitle = "";
    this.editGroupEdit = false;

    this.selectedGroup = [];

    setTimeout(() => {
      if (!(typeof this.inputGroupSearch === "undefined")) {
        this.inputGroupSearch.nativeElement.focus();
      }
    }, 100);
  }
  chatGroupEdit(item: any) {

    this.editGroupEdit = true;
    this.selectedGroup = item;
    this.groupTitle = item.groupTitle;

    setTimeout(() => {
      if (!(typeof this.inputEditGroupTitle === "undefined")) {
        this.inputEditGroupTitle.nativeElement.focus();
      }
    }, 100);
  }
  async enterGroupTitleKeyup($event: any) {
    $event.preventDefault();
    this.groupTitle = this.groupTitle.trim();
    if (this.groupTitle != '') {
      //this.groupTitleSendButton.nativeElement.focus();
      this.ChatGroupCreate();
    }
  }
  ChatGroupActivate() {
    var fd = {
      "ChatGroupId": this.selectedGroup.chatGroupId,
      "UpdatedBy": this.userData.userId
    }

    this.isLoading = true;
    this.httpService.post('api/chat/ChatGroupActivate/', fd)
      .subscribe(
        data => {
          this.groupTitle = "";
          this.chatGroupService.GetChatGroupDetails();
          this.GetChatGroupArchiveDetailsByUserId();
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        },
        error => {
        }
      );
  }
  ChaGroupArchive() {
    var fd = {
      "ChatGroupId": this.selectedGroup.chatGroupId,
      "UpdatedBy": this.userData.userId
    }
    var item = this.chatGroupService.chatGroupDetails.filter(x =>
      ((x.chatGroupId == this.selectedGroup.chatGroupId) && (x.userId == this.userData.userId))
    );
    //console.log(item);
    const index: number = this.chatGroupService.chatGroupDetails.indexOf(item);
    //console.log(index);
    if (index !== -1) {
      this.chatGroupService.chatGroupDetails.splice(index, 1);
    }
    this.isLoading = true;
    this.httpService.post('api/chat/ChatGroupDelete/', fd)
      .subscribe(
        data => {
          this.groupTitle = "";
          this.chatGroupService.GetChatGroupDetails();
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        },
        error => {
        }
      );
  }
  ChatGroupCreate(): void {
    this.groupTitleError = false;
    if (this.groupTitle == "") {
      this.groupTitleError = true;
      return;
    }

    if (this.editGroupEdit) {
      this.isLoading = true;
      //console.log("selectedGroup 3", this.selectedGroup);
      var fd = {
        "ChatGroupId": this.selectedGroup.chatGroupId,
        "GroupTitle": this.groupTitle,
        "UpdatedBy": this.userData.userId
      }
      this.httpService.post('api/chat/ChatGroupUpdate/', fd)
        .subscribe(
          data => {
            this.groupTitle = "";
            this.chatGroupService.GetChatGroupDetails();
            this.chatGroupEditCancel();
            this.isLoading = false;
          },
          error => {
          }
        );
    }
    else {

      if (this.groupAddUserIds.length == 0) {
        this.selectUserError = true;
        return;
      }
      this.isLoading = true;
      var f = {

        "CreatedBy": this.userData.userId,
        "GroupTitle": this.groupTitle
      }
      this.httpService.post('api/chat/ChatGroupCreate/', f)
        .subscribe(
          data => {

            this.chatGroupService.GetChatGroupDetails();
            
            if (this.groupAddUserId != 0) {
            
              if (data != null) {
                for (let n = 0; n < this.groupAddUserIds.length; ++n) {
                  this.chatGroupService.ChatGroupAddUser(data[0].chatGroupId, this.groupAddUserIds[n]);
                }
              }


              setTimeout(() => {
                this.groupTitle = "";
                this.groupAddNew = true;
                this.groupAddUserId = 0;
                this.groupAddUserIds = [];
                this.chatGroupClick(data[0]);
                this.isLoading = false;
              }, 5000);
            }
            else {
              this.groupTitle = "";
              this.isLoading = false;
            }
            
          },
          error => {
          }
        );
    }
  }

  validateFlag: boolean = true;
  async messageSend() {
    //console.log('start');
    this.validateFlag = true;
    if (this.message.trim() == "") {
      return;
    }
    this.validateFlag = await this.checkInput();
    //console.log("this.validateFlag ", this.validateFlag);
    if (!this.validateFlag) {

      return;
    }
    //console.log("vishal");
    
    
    if (this.ChatMode) {
      let d = new Date();


      //msg: chatMessage;
      let msg = new ChatMessage();

      msg.chatMessageId = this.globalMessageId + 1;
      msg.message = this.message;
      msg.updateMessage = msg.message;
      msg.saveFileName = "";
      msg.senderId = this.userData.userId;
      msg.receiverId = this.receiverUser[0].userID;
      msg.isSeen = false;
      msg.isDelete = false;
      msg.createdDate = moment(d).format('dddd, MMM DD YYYY');
      msg.createdDateOnly = moment(d).format('dddd, MMM DD YYYY');
      msg.createdTimeOnly = moment(d).format('hh:mma');
      msg.updatedDate = ""
      msg.senderName = "";
      msg.receiverName = "";
      msg.roomName = ""
      msg.seenDate = ""
      //console.log(msg);
      this.chatService.messageAll.push(msg);
      //this.chatMessageSendingCounter = this.chatMessageSendingCounter + 1;
      await this.chatService.ChatMessageCreate(this.message.trim(), '', this.receiverUser);

      //console.log(this.messageChat);
      this.message = "";
      if (!(typeof this.inputMessage === "undefined")) {
        this.inputMessage.nativeElement.focus();
      }
    }
  }

  async chatMessageDelete(item: any) {
    //console.log('chatMessageDelete', item);
    this.chatService.ChatMessageDelete(item.chatMessageId);
  }
  async messageUpdateCancel() {
    this.editMode = false;
    this.editMessageChat = [];
    this.message = "";
  }
  async chatMessageEdit(item: any) {
    //console.log('chatMessageDelete', item);
    this.editMode = true;
    this.editMessageChat = item;
    this.message = this.editMessageChat.message;
    if (!(typeof this.inputMessage === "undefined")) {
      this.inputMessage.nativeElement.focus();
    }
  }
  async messageUpdate() {
    //console.log('start');

    if (this.message == "") {
      return;
    }
    this.validateFlag = await this.checkInput();
    if (!this.validateFlag) {

      return;
    }
    if (this.ChatMode) {
      if (this.message != this.editMessageChat.message) {
        await this.chatService.ChatMessageUpdate(this.editMessageChat.chatMessageId, this.message);
        this.messageUpdateCancel();
        if (!(typeof this.inputMessage === "undefined")) {
          this.inputMessage.nativeElement.focus();
        }
      }
      else {
        this.messageUpdateCancel();
        if (!(typeof this.inputMessage === "undefined")) {
          this.inputMessage.nativeElement.focus();
        }
      }
    }
    
  }




  getGroupChatUsers(): any[] {

    if (!(typeof this.chatGroupService.chatUsers === "undefined")) {
      //console.log("getChatUsers", this.chatService.chatUsers);
      var users = this.chatGroupService.chatUsers.filter(x => x.userID != this.userData.userId);
      for (let n = 0; n < users.length; ++n) {
        if (users[n].isLogged) {
          users[n].loggedColor = {
            'border': "0px solid #33A532",
            'border-radius': "50%"
          };
          users[n].loggedCss = 'dot-logged';
        }
        else {
          users[n].loggedColor = {
            'border': "0px solid red",
            'border-radius': "50%"
          };
          users[n].loggedCss = 'dot';
        }
        if (this.groupAddUserId == 0) {
          if ((this.chatGroupService.chatGroupDetails.filter(x => ((x.chatGroupId == this.selectedGroup.chatGroupId) && (users[n].userID == x.userId)))).length == 0) {
            users[n].isChecked = false;
          }
          else { users[n].isChecked = true; }
        }
        else {
          if (users[n].userID == this.groupAddUserId) {
            users[n].isChecked = true;
          }
          else { users[n].isChecked = false; }
        }
      }
      return users.filter(it => it.userName.toLowerCase().includes(this.keyword.toLowerCase())).sort(function (a, b) {
        return b.isLogged - a.isLogged || b.unReadMessageCount - a.unReadMessageCount;
      });

    }
  }
  getGroupChatReceivers(chatGroupId: number): any {
    if (chatGroupId == -1) { chatGroupId = this.selectedGroup.chatGroupId; }
    if (!(typeof this.chatGroupService.chatUsers === "undefined")) {
      var receiverUsers = [];
      var users = this.chatGroupService.chatUsers.filter(x => x.userID != this.userData.userId).sort(function (a, b) {
        return b.userName - a.userName
      });
      var recName = "";
      for (let n = 0; n < users.length; ++n) {
        if ((this.chatGroupService.chatGroupDetails.filter(x => ((x.chatGroupId == chatGroupId) && (users[n].userID == x.userId)))).length != 0) {
          var item = users[n];
          if (item.isLogged) {
            item.loggedColor = {
              'border': "0px solid #33A532",
              'border-radius': "50%"
            };
            users[n].loggedCss = 'dot-logged';
          }
          else {
            item.loggedColor = {
              'border': "0px solid red",
              'border-radius': "50%"
            };
            users[n].loggedCss = 'dot';
          }
          receiverUsers.push(users[n]);
          //
        }
      }
      //console.log( receiverUsers);
      return receiverUsers.sort(function (a, b) {
        return b.isLogged - a.isLogged | b.userName - a.userName;
      });

    }
  }
  getGroupChatReceiverNames(chatGroupId: number): string {
    //if (chatGroupId == -1) { chatGroupId = this.selectedGroup.chatGroupId; }
    if (!(typeof this.chatGroupService.chatUsers === "undefined")) {
      var receiverUsers = [];
      var users = this.chatGroupService.chatUsers.filter(x => x.userID != this.userData.userId).sort(function (a, b) {
        return b.userName - a.userName
      });
      var recName = "";
      for (let n = 0; n < users.length; ++n) {
        if ((this.chatGroupService.chatGroupDetails.filter(x => ((x.chatGroupId == chatGroupId) && (users[n].userID == x.userId)))).length != 0) {
          receiverUsers.push(users[n]);
          if (recName === '') {
            recName = users[n].userName;
          }
          else {
            recName = recName + ' , ' + users[n].userName;
          }
        }
      }
      //console.log("recName " + chatGroupId, recName)
      return recName;
  
    }
  }
  getGroupArchiveChatReceiverNames(chatGroupId: number): string {
    if (chatGroupId == -1) { chatGroupId = this.selectedGroup.chatGroupId; }
    if (!(typeof this.groupDetailsArchive === "undefined")) {
      var receiverUsers = [];
      var users = this.chatGroupService.chatUsers.filter(x => x.userID != this.userData.userId).sort(function (a, b) {
        return b.userName - a.userName
      });
      var recName = "";

      for (let n = 0; n < users.length; ++n) {
        if ((this.groupDetailsArchive.filter(x => ((x.chatGroupId == chatGroupId) && (users[n].userID == x.userId)))).length != 0) {
          //receiverUsers.push(users[n]);
          if (recName === '') {
            recName = users[n].userName;
          }
          else {
            recName = recName + ' , ' + users[n].userName;
          }
        }
      }
      
      return recName;
      
    }
  }
  getGroupChatReceiverNamesRead(chatGroupId: number, chatGroupMessageId: number): any {

    if (!(typeof this.chatGroupService.chatUsers === "undefined")) {
      var receiverUsers = [];
      var users = this.chatGroupService.chatUsers.filter(x => x.userID != this.userData.userId).sort(function (a, b) {
        return b.userName - a.userName
      });
      
      var i = this.chatGroupService.groupMessageUnreadCount.filter(x => ((x.chatGroupId == chatGroupId) && (x.chatGroupMessageLastSeenId == chatGroupMessageId)));
      if (i.length != 0) {
        for (let n = 0; n < i.length; ++n) {
          var u = users.filter(x => (x.userID == i[n].userId));
          if (u.length != 0) {
            if (i[n].chatGroupMessageLastSeenId != 0) {
              let d = new Date(i[n].seenDate);
              u[0].seenTitle = u[0].userName + '\n[' + moment(d).format('MM/DD/YYYY hh:mma') + ']';
              //u[0].chatGroupMessageLastSeenId = i[n].chatGroupMessageLastSeenId;
              receiverUsers.push(u[0]);
            }

          }
        }
      }

      //console.log("receiverUsers",receiverUsers);
      return receiverUsers.sort(function (a, b) {
        return b.userName - a.userName;
      });

    }
  }

  getChatReceiverNamesRead(): any {

    if (!(typeof this.receiverUser === "undefined")) {

      var receiverUsers = [];

      for (let n = 0; n < this.receiverUser.length; ++n) {

        if (this.receiverUser[n].unReadMessage == 0) {

          receiverUsers.push(this.receiverUser[n]);
        }
      }

      return receiverUsers.sort(function (a, b) {
        return b.userName - a.userName;
      });

    }
  }
  getChatReceiverNamesReadTitle(userName: string, seenDate: string): string {

    let d = new Date(seenDate);
    return userName + '\n[' + moment(d).format('MM/DD/YYYY hh:mma') + ']';
  }
  getChatGroupByuserID(): any[] {

    var gd: any[] = [];
    
    if (!(typeof this.chatGroupService.chatGroupDetails === "undefined")) {
    
      var groupDetails = this.chatGroupService.chatGroupDetails.filter(x => (x.userId == this.userData.userId))
    
      for (let n = 0; n < groupDetails.length; n++) {
        var i = groupDetails[n];

        if (!(typeof this.chatGroupService.groupMessageUnreadCount === "undefined")) {

          var item = this.chatGroupService.groupMessageUnreadCount.filter(x =>
            ((x.chatGroupId == groupDetails[n].chatGroupId) && (x.userId == this.userData.userId)));
          if (item.length == 0) { i.unReadMessage = ''; }
          else if (item[0].unReadMessageCount == 0) { i.unReadMessage = ''; }
          else { i.unReadMessage = item[0].unReadMessageCount; }
        }
        else { i.unReadMessage = ''; }

        i.receiverNames = this.getGroupChatReceiverNames(i.chatGroupId);
        if (this.groupkeyword != '') {
          var f = this.chatGroupService.chatGroupDetails.filter(x => x.userName.toLowerCase().includes(this.groupkeyword.toLowerCase()));
          f = f.filter(x => x.chatGroupId == groupDetails[n].chatGroupId);
          if (f.length != 0) {
            gd.push(i);
          }
          else {
            if (groupDetails[n].groupTitle.toLowerCase().includes(this.groupkeyword.toLowerCase())) {
              gd.push(i);
            }
          }
        }
        else {
    
          gd.push(i);
    
        }
        
      }
      
      gd = gd
        .sort(function (a, b) {
          return a.isDelete - b.isDelete || b.unReadMessage - a.unReadMessage;
        })

        ;
    }
    return gd;

  }
  getChatGroupArchiveByuserID(): any[] {
    
    var gd: any[] = [];
    if (!(typeof this.groupDetailsArchive === "undefined")) {
      var groupDetails = this.groupDetailsArchive.filter(x => (x.userId == this.userData.userId))
      for (let n = 0; n < groupDetails.length; n++) {
        var i = groupDetails[n];
        if (!(typeof this.chatGroupService.groupMessageUnreadCount === "undefined")) {
          var item = this.chatGroupService.groupMessageUnreadCount.filter(x =>
            ((x.chatGroupId == groupDetails[n].chatGroupId) && (x.userId == this.userData.userId)));
          if (item.length == 0) { i.unReadMessage = ''; }
          else if (item[0].unReadMessageCount == 0) { i.unReadMessage = ''; }
          else { i.unReadMessage = item[0].unReadMessageCount; }
        }
        else { i.unReadMessage = ''; }
        i.receiverNames = this.getGroupArchiveChatReceiverNames(i.chatGroupId);
        if (this.groupkeyword != '') {
          var f = this.groupDetailsArchive.filter(x => x.userName.toLowerCase().includes(this.groupkeyword.toLowerCase()));
          f = f.filter(x => x.chatGroupId == groupDetails[n].chatGroupId);
          if (f.length != 0) {
            gd.push(i);
          }
          else {
            if (groupDetails[n].groupTitle.toLowerCase().includes(this.groupkeyword.toLowerCase())) {
              gd.push(i);
            }
          }
        }
        else {
    
          gd.push(i);
    
        }
    
      }

      gd = gd
        .sort(function (a, b) {
          return a.isDelete - b.isDelete || b.unReadMessage - a.unReadMessage;
        })
    }

    return gd;
  }
  getChatGroupMessageDate() {
    var messageChatDate;
    if (!(typeof this.chatGroupService.chatGroupMessages === "undefined")) {
      this.globalMessageId = Math.max.apply(Math, this.chatGroupService.chatGroupMessages.filter(x => (
        (x.chatGroupId == this.selectedGroup.chatGroupId)
      )).map(function (o) { return o.chatGroupMessageId; }));

      this.chatGroupMessagesLocal = this.chatGroupService.chatGroupMessages.filter(x => x.chatGroupId == this.selectedGroup.chatGroupId);
      messageChatDate = this.chatGroupService.chatGroupMessages.filter(x => (
        (x.chatGroupId == this.selectedGroup.chatGroupId)
      )).map(item => item.createdDateOnly)
        .filter((value, index, self) => self.indexOf(value) === index);

    }
    return messageChatDate;

  }
  getChatGroupMessageByDate(dt: string): any[] {

    
    let chatMessage: any[] = this.chatGroupMessagesLocal.filter(x => x.createdDateOnly == dt);
    for (let n = 0; n < chatMessage.length; ++n) {
     if (chatMessage[n].updateMessage == '') {
        chatMessage[n].updateMessage = this.updateChatGroupMesageForSearch(chatMessage[n].chatMessageId, chatMessage[n].message);

      }
    }
    return chatMessage.sort(function (a, b) {
      return a.chatGroupMessageId - b.chatGroupMessageId;
    });
  }
  async AddUser(item: any) {
    this.groupChatAddUserMode = true;
    this.selectedGroup = item;
    this.groupAddNew = false;
    
  }
  async setUserInGroup(userId: number) {
    
    if (!this.groupAddNew) {
      this.chatGroupService.ChatGroupAddUser(this.selectedGroup.chatGroupId, userId);
    }
    else {
            const index: number = this.groupAddUserIds.indexOf(userId);
      if (index !== -1) {
        this.groupAddUserIds.splice(index, 1);
      }
      else {
        this.groupAddUserIds.push(userId);
      }
      if (!(typeof this.inputGroupTitle === "undefined")) {
        this.inputGroupTitle.nativeElement.focus();
      }
      
    }
  }
  checkedUser(item: any): string {
    
    return 'checked';
  }
  async chatGroupMessageSend() {
    

    if (this.groupmessage == "") {
      return;
    }
    this.validateFlag = await this.checkInput();
    
    
    if (!this.validateFlag) {

      return;
    }
    if (!this.ChatMode) {
      this.chatMessageSendingCounter = this.chatMessageSendingCounter + 1;
      let d = new Date();


      let msg = new ChatGroupMessage();

      msg.chatGroupMessageId = this.globalMessageId + 1;
      msg.chatGroupId = this.selectedGroup.chatGroupId;
      msg.message = this.groupmessage;
      msg.updateMessage = msg.message;
      msg.saveFileName = "";
      msg.senderId = this.userData.userId;
      msg.isDelete = false;
      msg.createdDate = moment(d).format('dddd, MMM DD YYYY');
      msg.createdDateOnly = moment(d).format('dddd, MMM DD YYYY');
      msg.createdTimeOnly = moment(d).format('hh:mma');
      msg.updatedDate = ""
      msg.senderName = "";

      this.chatGroupService.chatGroupMessages.push(msg);
      await this.chatGroupService.ChatGroupMessageCreate(this.selectedGroup.chatGroupId, this.groupmessage, '');
      this.chatGroupService.SetChatGroupMessageAWSeen(this.selectedGroup.chatGroupId);
      this.groupmessage = "";
      if (!(typeof this.inputGroupmessage === "undefined")) {
        this.inputGroupmessage.nativeElement.focus();
      }
    }
  }
  setMessageInputfocus() {
    if (!(typeof this.inputMessage === "undefined")) {
      this.inputMessage.nativeElement.focus();
    }
  }
  setGroupMessageInputfocus() {
    if (!(typeof this.inputGroupmessage === "undefined")) {
      this.inputGroupmessage.nativeElement.focus();
    }
  }
  async chatGroupMessageDelete(item: any) {
    this.chatGroupService.ChatGroupMessageDelete(item.chatGroupMessageId);
  }
  async messageGroupUpdateCancel() {
    this.editMode = false;
    this.editGroupMessageChat = [];
    this.groupmessage = "";
  }
  async chatGroupMessageEdit(item: any) {
    this.editMode = true;
    this.editGroupMessageChat = item;
    this.groupmessage = this.editGroupMessageChat.message;
    if (!(typeof this.inputGroupmessage === "undefined")) {
      this.inputGroupmessage.nativeElement.focus();
    }
  }
  async messageGroupUpdate() {
    
    if (this.groupmessage == "") {
      return;
    }
    this.validateFlag = await this.checkInput();
    if (!this.validateFlag) {

      return;
    }
    if (!this.ChatMode) {
      if (this.groupmessage != this.editGroupMessageChat.message) {
        await this.chatGroupService.ChatGroupMessageUpdate(this.editGroupMessageChat.chatGroupMessageId, this.groupmessage);
        this.messageGroupUpdateCancel();
        if (!(typeof this.inputGroupmessage === "undefined")) {
          this.inputGroupmessage.nativeElement.focus();
        }
      }
      else {
        this.messageGroupUpdateCancel();
        if (!(typeof this.inputGroupmessage === "undefined")) {
          this.inputGroupmessage.nativeElement.focus();
        }
      }
    }
    //console.log('chatMessageDeleteEdit');
  }
  
  async onKeyUpsearchConversation(event: any) {
    if (!this.ChatMode) {
      if (!(typeof this.selectedGroup === "undefined")) {
        if (this.selectedGroup.length != 0) {
          this.chatHistoryTitle = this.chatHistoryTitle + ' ( ' + this.selectedGroup.groupTitle + ' ) ';
        }
      }
      if (event.target.value.length > 1) {
        this.httpService.get('api/Chat/GetChatGroupMessageKeyWordByChatGroupId/' + event.target.value + '/' + this.userData.userId)
          .subscribe(
            data => {
              this.chatGroupHistotyMessage = <any[]>data;
            },
            error => {
            }
          );
      }
    }
    else {
      var receiverId = '-1';
      if (this.chatHistoryFlag) {
        if (!(typeof this.receiverUser === "undefined")) {
          if (this.receiverUser.length != 0) {
            receiverId = this.receiverUser[0].userID;
          }
        }
      }

      if (event.target.value.length > 1) {
        this.httpService.get('api/Chat/GetChatMessageByKeyWord/' + event.target.value + '/' + this.userData.userId + '/' + receiverId)
          .subscribe(
            data => {
              this.chatHistotyMessage = <any[]>data;
            },
            error => {
            }
          );
      }
    }
  }
  getChatMessageByKeyWordReceiver() {
    var receiverName;
    if (!(typeof this.chatHistotyMessage === "undefined")) {

      receiverName = this.chatHistotyMessage.filter(x => (
        ((x.receiverId == this.userData.userId) || (x.senderId == this.userData.userId))
      )).map(item => (item.roomName + ' - ' + item.receiverId))
        .filter((value, index, self) => self.indexOf(value) === index);

    }
    return receiverName;

  }
  getChatMessageByReceiver(r: string) {
    var arr = r.split(' - ');
    var msg;
    if (!(typeof this.chatHistotyMessage === "undefined")) {

      msg = this.chatHistotyMessage.filter(x =>
        ((x.senderId == arr[1]) || (x.receiverId == arr[1]))
      ).sort(function (a, b) {
        return a.ChatMessageId - b.ChatMessageId;
      });
    }

    return msg;

  }
  getReceiverName(item: string) {
    var arr = item.split(' - ');
    return arr[0];
  }

  async enterGroupKeyup($event: any) {
    $event.preventDefault();
    if (this.chatGroupMessageImageMode) {
      this.uploadScreenShot(this.chatGroupMessageImage);
      
    }
    else {
      2
      
      let msg: string = this.groupmessage.trim();
      let validateFlag: boolean = true;
      if (msg.substr(msg.length - 4, 4).toLowerCase() == '#sku') {
        validateFlag = false;
      }
      if (msg.substr(msg.length - 8, 8).toLowerCase() == '#orderid') {
        validateFlag = false;
      }
      if (msg.substr(msg.length - 7, 7).toLowerCase() == '#ticket') {
        validateFlag = false;
      }
      this.groupmessage = this.groupmessage.trim();
      if ((this.groupmessage != '') && (validateFlag)) {
        
        if (this.editMode) {
          this.messageGroupUpdate();
          
        } else {
          this.chatGroupMessageSend();
          
        }
      }
    }
  }
  async enterKeyup($event: any) {
    
    $event.preventDefault();
    
    if (this.chatMessageImageMode) {
      await this.uploadScreenShot(this.chatMessageImage);
    }
    else {
    
      let msg: string = this.message.trim();
      let validateFlag: boolean = true;
      
      if (msg.length >= 4) {
        if (msg.substr(msg.length - 4, 4).toLowerCase() == '#sku') {
          validateFlag = false;
        }
      }
      if (msg.length >= 8) {
        if (msg.substr(msg.length - 8, 8).toLowerCase() == '#orderid') {
          validateFlag = false;
        }
      }
      if (msg.length >= 7) {
        if (msg.substr(msg.length - 7, 7).toLowerCase() == '#ticket') {
          validateFlag = false;
        }
      }
      
      this.message = this.message.trim();
      if ((this.message != '') && (validateFlag)) {
        
        if (this.editMode) {
          this.messageUpdate()
        } else {
          this.messageSend();
        }
      }
    }
  }
  async onKeyMessageEsc($event: any) {
    
    if (this.ChatMode) {
      this.chatMessageImageMode = false;
      this.chatMessageImage = null;
      this.messageUpdateCancel();
      if (!(typeof this.inputMessage === "undefined")) {
        this.inputMessage.nativeElement.focus();
      }
    }
    else {
      this.chatGroupMessageImageMode = false;
      this.chatGroupMessageImage = null;
      this.messageGroupUpdateCancel();
      if (!(typeof this.inputGroupmessage === "undefined")) {
        this.inputGroupmessage.nativeElement.focus();
      }
    }
  }

  public userMenuItems: MenuItemModel[] = [
    {
      text: 'New Group',
      iconCss: 'glyphicon glyphicon-plus'
    }];


  userMenuOpen($event: any, item: any) {
    this.selectedUser = item;
    let contextmenuObj: ContextMenu = getInstance(document.getElementById("contextUsermenu"), ContextMenu) as ContextMenu;
    $event.preventDefault();
    contextmenuObj.open(window.scrollY - this.margin+$event.clientY, $event.clientX);
  }
  userMenuSelect(args: MenuEventArgs) {

    if (args.item.text == 'New Group') {
      this.GroupCreateClick(this.selectedUser);
    }
  }

  public addDisabled(args: MenuEventArgs) {
    if (!this.selectedGroup.isAdmin) {
      args.element.classList.add('e-disabled');
    }
  }
  public groupItems: MenuItemModel[] = [
    {
      text: 'New Group',
      iconCss: 'glyphicon glyphicon-plus'
    },
    {
      text: 'Archive List',
      iconCss: 'glyphicon glyphicon-remove'
    }];


  groupMenuOpen($event) {
    let contextmenuObj: ContextMenu = getInstance(document.getElementById("contextmenu"), ContextMenu) as ContextMenu;
    $event.preventDefault();
    contextmenuObj.open(window.scrollY - this.margin+$event.clientY, $event.clientX);
  }
  groupMenuSelect(args: MenuEventArgs) {
    if (args.item.text == 'Archive List') {
      this.groupChatArchiveMode = false;
      this.GetChatGroupArchiveDetailsByUserId()
    }
    if (args.item.text == 'New Group') {
      this.GroupCreateClick(null);
    }
  }
  // ContextMenu items definition 
  public menuItems: MenuItemModel[] = [
    {
      text: 'Add/Remove User',
      iconCss: 'glyphicon glyphicon-plus'
    },
    {
      text: 'Edit Group Name',
      iconCss: 'glyphicon glyphicon-edit'
    },
    {
      text: 'Archive',
      iconCss: 'glyphicon glyphicon-remove',

    }];

  // Event triggers once the context menu rendering is completed.
  onCreated(): void {
    if (Browser.isDevice) {
      //this.content = 'Touch hold to open the ContextMenu';
      this.contextMenuGroup.animationSettings.effect = 'ZoomIn';
    } else {
      //this.content = 'Right click / Touch hold to open the ContextMenu';
      this.contextMenuGroup.animationSettings.effect = 'SlideDown';
    }
  }

  itemSelect(args: MenuEventArgs) {
    if (args.item.text == 'Add/Remove User') {
      this.gTitle = this.selectedGroup.groupTitle;
      this.AddUser(this.selectedGroup);
    }
    if (args.item.text == 'Edit Group Name') {
      this.chatGroupEdit(this.selectedGroup);
    }
    if (args.item.text == 'Archive') {
      this.ChaGroupArchive();
    }
  }
  groupListMenuOpen($event, item) {
    this.selectedGroup = item;
    let contextmenuObj: ContextMenu = getInstance(document.getElementById("contextMenuGroup"), ContextMenu) as ContextMenu;
    $event.preventDefault();

    contextmenuObj.open(window.scrollY - this.margin+$event.clientY, $event.clientX);
  }
  groupArchiveListMenuOpen($event, item) {
    this.selectedGroup = item;
    let contextmenuObj: ContextMenu = getInstance(document.getElementById("contextMenuGroupArchive"), ContextMenu) as ContextMenu;
    $event.preventDefault();

    contextmenuObj.open(window.scrollY - this.margin+$event.clientY, $event.clientX);
  }
  public menuItemsArchive: MenuItemModel[] = [

    {
      text: 'Restore Group',
      iconCss: 'glyphicon glyphicon-plus',

    }];
  
  // Event triggers once the context menu rendering is completed.
  onCreatedArchive(): void {
    if (Browser.isDevice) {
      //this.content = 'Touch hold to open the ContextMenu';
      this.contextMenuGroupArchive.animationSettings.effect = 'ZoomIn';
    } else {
      //this.content = 'Right click / Touch hold to open the ContextMenu';
      this.contextMenuGroupArchive.animationSettings.effect = 'SlideDown';
    }
  }
  itemSelectArchive(args: MenuEventArgs) {
    //console.log("args", i)
    //console.log("args.item.text", args.item.text)
    //this.selectedGroup = i;

    if (args.item.text == 'Restore Group') {
      this.ChatGroupActivate();
    }
  }
  public menuMessageItems: MenuItemModel[] = [
    {
      text: 'Copy',
      iconCss: 'glyphicon glyphicon-copy'
    },
    {
      text: 'Edit',
      iconCss: 'glyphicon glyphicon-edit'
    },
    {
      text: 'Delete',
      iconCss: 'glyphicon glyphicon-remove',

    }];

  public addMessageDisabled(args: MenuEventArgs, i: any) {
    //console.log("i.isDelete ", i.isDelete);
    if (this.userData.userId === i.receiverId) {
      if (args.item.text != 'Copy') {
        args.element.classList.add('e-disabled');
      }
    }
    else {
      if (i.isDelete) {
        if (args.item.text != 'Copy') {
          args.element.classList.add('e-disabled');
        }

      }
      else {
        if (i.saveFileName != '') {
          if (args.item.text != 'Delete') {
            args.element.classList.add('e-disabled');
          }
        }
      }
    }
  }
  // Event triggers once the context menu rendering is completed.
  onMessageCreated(): void {
    if (Browser.isDevice) {
      //this.content = 'Touch hold to open the ContextMenu';
      this.contextMenuMessage.animationSettings.effect = 'ZoomIn';
    } else {
      //this.content = 'Right click / Touch hold to open the ContextMenu';
      this.contextMenuMessage.animationSettings.effect = 'SlideDown';
    }
  }
  itemMessageSelect(args: MenuEventArgs, i: any) {
    //console.log("args", i)
    //console.log("args.item.text", args.item.text)
    //this.selectedGroup = i;
    if (args.item.text == 'Copy') {
      this.copyMessage(i.message);
    }
    if (args.item.text == 'Edit') {
      this.chatMessageEdit(i);
    }
    if (args.item.text == 'Delete') {
      this.chatMessageDelete(i);
    }
    //if (args.item.text == 'Disable') {
    //  this.ChaGroupArchive();
    //}
  }
  public addMessageGroupDisabled(args: MenuEventArgs, i: any) {
    //console.log("i.isDelete ", i.isDelete);
    if (this.groupChatArchiveMode) {
      if (args.item.text != 'Copy') {
        args.element.classList.add('e-disabled');
      }
    } else {
      if (this.userData.userId != i.senderId) {
        if (args.item.text != 'Copy') {
          args.element.classList.add('e-disabled');
        }
      }
      else {
        if (i.isDelete) {
          if (args.item.text != 'Copy') {
            args.element.classList.add('e-disabled');
          }
        }
      }
    }
  }
  // Event triggers once the context menu rendering is completed.
  onMessageGroupCreated(): void {
    if (Browser.isDevice) {
      //this.content = 'Touch hold to open the ContextMenu';
      this.contextMenuGroupMessage.animationSettings.effect = 'ZoomIn';
    } else {
      //this.content = 'Right click / Touch hold to open the ContextMenu';
      this.contextMenuGroupMessage.animationSettings.effect = 'SlideDown';
    }
  }
  itemMessageGroupSelect(args: MenuEventArgs, i: any) {
    //console.log("args", i)
    //console.log("args.item.text", args.item.text)
    //this.selectedGroup = i;
    if (args.item.text == 'Copy') {
      this.copyMessage(i.message);
    }
    if (args.item.text == 'Edit') {
      this.chatGroupMessageEdit(i);
    }
    if (args.item.text == 'Delete') {
      this.chatGroupMessageDelete(i);
    }
    //if (args.item.text == 'Disable') {
    //  this.ChaGroupArchive();
    //}
  }
  copyMessage(mesage: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = mesage;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  checkNewMesageNotification(): boolean {
    
    var bool = true;
    var count: number = 0;
    var audioCount: number = 0;
    if (!(typeof this.chatService.messageAll === "undefined")) {
      
      if (this.receiverUser.length > 0) {
        if (this.messageAllLocal.filter(x => (
          ((x.senderId == this.receiverUser[0].userID) && (x.receiverId == this.userData.userId))
        )).length > 0) {
          let localRecceivingMessageId: number = Math.max.apply(Math, this.messageAllLocal.filter(x => (
            ((x.senderId == this.receiverUser[0].userID) && (x.receiverId == this.userData.userId))
          )).map(function (o) { return o.chatMessageId; }));
          if (this.globalRecceivingMessageId != localRecceivingMessageId) {
            if ((this.focusFlag)|| (this.globalRecceivingMessageId==-1)) {
              this.globalRecceivingMessageId = localRecceivingMessageId;
            }
            else {
              if (this.globalRecceivingMessageId < localRecceivingMessageId) {
                let counter: number = localRecceivingMessageId - this.globalRecceivingMessageId;
                while (counter > 0) {
                  //console.log('play 1');
                  this.playAudio();
                  counter = counter - 1;
                }
              }
              this.globalRecceivingMessageId = localRecceivingMessageId;
            }
          }
          //console.log("this.globalRecceivingMessageId ", this.globalRecceivingMessageId);
        }
      }
    }
    if (!(typeof this.chatGroupService.chatGroupMessages === "undefined")) {
      
      if (this.chatGroupService.chatGroupMessages.length > 0) {
        if (!(typeof this.selectedGroup === "undefined")) {
      
            let localRecceivingGroupMessageId: number = Math.max.apply(Math, this.chatGroupService.chatGroupMessages.filter(x => (
              ((x.chatGroupId == this.selectedGroup.chatGroupId) && (x.senderId != this.userData.userId))
            )).map(function (o) { return o.chatGroupMessageId; }));
            
            if (this.globalRecceivingGroupMessageId != localRecceivingGroupMessageId) {
              if ((this.focusFlag)|| (this.globalRecceivingGroupMessageId==-1)) {
                this.globalRecceivingGroupMessageId = localRecceivingGroupMessageId;
              }
              else {
      
                if (this.globalRecceivingGroupMessageId < localRecceivingGroupMessageId) {
                  let counter: number = localRecceivingGroupMessageId - this.globalRecceivingGroupMessageId;
                  //console.log("counter ", counter);
                  while (counter > 0) {
                    //console.log('play 2');
                    this.playAudio();
                    counter = counter - 1;
                  }
                }
                this.globalRecceivingGroupMessageId = localRecceivingGroupMessageId;
              }
            }
      
        }
      }

    }
    if (!(typeof this.chatService.messageUnreadCount === "undefined")) {
      this.chatService.messageUnreadCount.filter(x => (x.receiverId == this.userData.userId))
      //console.log("Test", this.chatService.messageUnreadCount.filter(x => (x.receiverId == this.userData.userId)));
      var userUnreadMessage = this.chatService.messageUnreadCount.filter(x => (x.receiverId == this.userData.userId));
      for (let n = 0; n < userUnreadMessage.length; ++n) {
        count = count + userUnreadMessage[n].unReadMessageCount;
        
      }
      this.totalUnReadChatMessageCount = count;
    }
    if (!(typeof this.chatGroupService.groupMessageUnreadCount === "undefined")) {
      //console.log("groupMessageUnreadCount ", this.chatGroupService.groupMessageUnreadCount);
      //this.chatService.messageUnreadCount.filter(x => (x.receiverId == this.userData.userId))
      this.totalUnReadChatGroupMessageCount = 0;
      var userUnreadMessage = this.chatGroupService.groupMessageUnreadCount.filter(x => (x.userId == this.userData.userId));
      for (let n = 0; n < userUnreadMessage.length; ++n) {
        if (userUnreadMessage[n].unReadMessageCount > 0) {
          count = count + userUnreadMessage[n].unReadMessageCount;
          this.totalUnReadChatGroupMessageCount = this.totalUnReadChatGroupMessageCount + userUnreadMessage[n].unReadMessageCount;
          //bool = false;
        }
      }
    }
    
    
    if ((this.totalUnReadChatMessageCount != -1) && (this.totalUnReadChatGroupMessageCount != -1)) {
      if (count > 0) {
        bool = false

    
        if (this.totalUnReadMessageCount == -1) { this.totalUnReadMessageCount = count }
        else {
          if (this.totalUnReadMessageCount < count) {
            let counter: number = count - this.totalUnReadMessageCount;
            while (counter > 0) {
              //console.log('play 3');
              this.playAudio();
              counter = counter - 1;
            }
          }
          this.totalUnReadMessageCount = count
        }
      }
      else {
        this.totalUnReadMessageCount = 0;
      }
    }
    return bool;
  }
  playAudio() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/alarm.mp3";
    audio.load();
    audio.play();
  }
  returnMessage(i: any): string {
    //console.log("i=", i);
    if (i.saveFileName != '') {
      return '<a href="/' + i.saveFileName + '" >' + i.message + '</a>';
    }
    else {
      return i.message;
    }
  }
  messageUpdateTitle(item: any): any {
    var title = '';
    if ((item.updatedDate != '') && (!item.isDelete)) {
      title = 'This message has been edited.\n [' + item.updatedDate + ']';

    }
    if ((item.updatedDate != '') && (item.isDelete)) {
      title = 'This message has been deleted.\n [' + item.updatedDate + ']';

    }
    return title;

  }
  GetChatGroupArchiveDetailsByUserId() {
    //console.log("GetChatGroupArchiveDetailsByUserId");
    if (this.groupChatArchiveMode) { this.groupChatArchiveMode = false }
    else {
      this.isLoading = true;
      this.groupChatArchiveMode = true;
      this.httpService.get('api/Chat/GetChatGroupArchiveDetailsByUserId/' + this.userData.userId)
        .subscribe(
          data => {
            this.groupDetailsArchive = <any[]>data;
            //console.log('GetChatGroupArchiveDetailsByUserId ', data);
            this.isLoading = false;
            setTimeout(() => {
              if (!(typeof this.inputGroupSearch === "undefined")) {
                this.inputGroupSearch.nativeElement.focus();
              }
            }, 500);
          },
          error => {
            this.groupChatArchiveMode = false
            this.isLoading = false;
            //console.log('error');
          }

        );
    }
  }
  clearSearch() {
    this.chatMessagekeyword = '';
    setTimeout(() => {
      if (!(typeof this.inputSearch === "undefined")) {
        this.inputSearch.nativeElement.focus();
      }
    }, 500);

  }
  clearGroupSearch() {
    this.groupkeyword = '';
    setTimeout(() => {
      if (!(typeof this.inputGroupSearch === "undefined")) {
        this.inputGroupSearch.nativeElement.focus();
      }
    }, 500);

  }
  clearChatMessageSearch() {
    this.chatMessagekeyword = '';
    //setTimeout(() => {
    //  this.inputChatMessageSearch.nativeElement.focus();
    //}, 100);
    this.chatMessageSearchShow();
  }
  clearChatGroupMessageSearch() {
    this.chatGroupMessagekeyword = '';
    this.chatGroupMessageSearchShow();
    //setTimeout(() => {
    //  this.inputChatGroupMessageSearch.nativeElement.focus();
    //}, 100);

  }
  //getRandomColor() {
  //  var color = Math.floor(0x1000000 * Math.random()).toString(16);
  //  return '#' + ('000000' + color).slice(-6);
  //}
  onImgError(event) {
    event.target.src = '/assets/images/file.png';
  }
  

  pasteChatMessageImage($event: any) {
    //console.log('start');
    //console.log($event);
    const items = ($event.clipboardData || $event.originalEvent.clipboardData).items;
    //let blob = null;
    this.chatMessageImage = null;
    //console.log(items);
    for (const item of items) {
      if (item.type.indexOf('text') === 0) {
        break;
      }
      if (item.type.indexOf('image') === 0) {
        this.chatMessageImage = item.getAsFile();
      }
    }

    // load image if there is a pasted image
    if (this.chatMessageImage !== null) {
      //console.log(this.chatMessageImage);
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        this.chatMessageImageMode = true;
        //console.log("Image ",evt.target.result); // data url!
        setTimeout(() => {
          this.imgRenderer.nativeElement.src = evt.target.result;
        },100);

      };
      reader.readAsDataURL(this.chatMessageImage);

    }
  }
  pasteChatGroupMessageImage($event: any) {
    //console.log('start');
    //console.log($event);
    const items = ($event.clipboardData || $event.originalEvent.clipboardData).items;
    //let blob = null;
    this.chatGroupMessageImage = null;
    //console.log(items);
    for (const item of items) {
      //console.log(item.type);
      if (item.type.indexOf('text') === 0) {
        break;
      }
      if (item.type.indexOf('image') === 0) {
        this.chatGroupMessageImage = item.getAsFile();
      }
    }

    // load image if there is a pasted image
    if (this.chatGroupMessageImage !== null) {
      //console.log(this.chatGroupMessageImage);
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        this.chatGroupMessageImageMode = true;
        //console.log("Image ",evt.target.result); // data url!
        setTimeout(() => {
          this.imgGroupRenderer.nativeElement.src = evt.target.result;
        }, 100);

      };
      reader.readAsDataURL(this.chatGroupMessageImage);

    }
  }
  public uploadScreenShot = (files) => {
    if (files.length === 0) {
      return;
    }
    
    let fileToUpload = <File>files;

    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.chatMessageSendingCounter = this.chatMessageSendingCounter + 1;
    this.http.post('/api/Chat/upload', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          //this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
          //console.log(event.ok);
          //event.
          //console.log(event.status);
          var data = <any>event.body;
          if (this.ChatMode) {
            let d = new Date();


            //msg: chatMessage;
            let msg = new ChatMessage();

            msg.chatMessageId = this.globalMessageId + 1;
            msg.message = data.fileName;
            msg.updateMessage = msg.message;
            msg.saveFileName = data.saveFileName;
            msg.senderId = this.userData.userId;
            msg.receiverId = this.receiverUser[0].userID;
            msg.isSeen = false;
            msg.isDelete = false;
            msg.createdDate = moment(d).format('dddd, MMM DD YYYY');
            msg.createdDateOnly = moment(d).format('dddd, MMM DD YYYY');
            msg.createdTimeOnly = moment(d).format('hh:mma');
            msg.updatedDate = ""
            msg.senderName = "";
            msg.receiverName = "";
            msg.roomName = ""
            msg.seenDate = ""
            
            this.chatService.messageAll.push(msg);
            this.chatMessageImageMode = false;
            this.chatMessageImage = null;
            this.chatService.ChatMessageCreate(data.fileName, data.saveFileName, this.receiverUser);
            if (this.message != '') {
              if (this.editMode) {
                this.messageUpdate()
                //this.chatUpdateButton.nativeElement.focus();
              } else {
                this.messageSend();
                //this.chatSendButton.nativeElement.focus();
              }
            }
            if (!(typeof this.inputMessage === "undefined")) {
              this.inputMessage.nativeElement.focus();
            }
          }
          else {
            let d = new Date();


            //msg: chatMessage;
            let msg = new ChatGroupMessage();

            msg.chatGroupMessageId = this.globalMessageId + 1;
            msg.chatGroupId = this.selectedGroup.chatGroupId;
            msg.message = this.groupmessage;
            msg.updateMessage = msg.message;
            msg.saveFileName = "";
            msg.senderId = this.userData.userId;
            msg.isDelete = false;
            msg.createdDate = moment(d).format('dddd, MMM DD YYYY');
            msg.createdDateOnly = moment(d).format('dddd, MMM DD YYYY');
            msg.createdTimeOnly = moment(d).format('hh:mma');
            msg.updatedDate = ""
            msg.senderName = "";

            //console.log(msg);
            this.chatGroupService.chatGroupMessages.push(msg);
            this.chatGroupMessageImageMode = false;
            this.chatGroupMessageImage = null;
            this.chatGroupService.ChatGroupMessageCreate(this.selectedGroup.chatGroupId, data.fileName, data.saveFileName);
            if (this.groupmessage != '') {
        if (this.editMode) {
          this.messageGroupUpdate();
          //this.chatGroupUpdateButton.nativeElement.focus();
        } else {
          this.chatGroupMessageSend();
          //this.chatGroupSendButton.nativeElement.focus();
        }
      }
            if (!(typeof this.inputGroupmessage === "undefined")) {
              this.inputGroupmessage.nativeElement.focus();
            }
          }
          //console.log(data.fileName);
          //console.log(data.saveFileName);
        }
      }),
      (data => {
        //console.log(data);
      })
      ;
  }
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.chatMessageSendingCounter = this.chatMessageSendingCounter + 1;
    this.http.post('/api/Chat/upload', formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          //this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
          //console.log(event.ok);
          //event.
          //console.log(event.status);
          var data = <any>event.body;
          if (this.ChatMode) {
            let d = new Date();


            //msg: chatMessage;
            let msg = new ChatMessage();

            msg.chatMessageId = this.globalMessageId + 1;
            msg.message = data.fileName;
            msg.updateMessage = msg.message;
            msg.saveFileName = data.saveFileName;
            msg.senderId = this.userData.userId;
            msg.receiverId = this.receiverUser[0].userID;
            msg.isSeen = false;
            msg.isDelete = false;
            msg.createdDate = moment(d).format('dddd, MMM DD YYYY');
            msg.createdDateOnly = moment(d).format('dddd, MMM DD YYYY');
            msg.createdTimeOnly = moment(d).format('hh:mma');
            msg.updatedDate = ""
            msg.senderName = "";
            msg.receiverName = "";
            msg.roomName = ""
            msg.seenDate = ""
            //console.log(msg);
            this.chatService.messageAll.push(msg);
            this.chatService.ChatMessageCreate(data.fileName, data.saveFileName, this.receiverUser);
            if (!(typeof this.inputMessage === "undefined")) {
              this.inputMessage.nativeElement.focus();
            }
          }
          else {
            let d = new Date();


            //msg: chatMessage;
            let msg = new ChatGroupMessage();

            msg.chatGroupMessageId = this.globalMessageId + 1;
            msg.chatGroupId = this.selectedGroup.chatGroupId;
            msg.message = this.groupmessage;
            msg.updateMessage = msg.message;
            msg.saveFileName = "";
            msg.senderId = this.userData.userId;
            msg.isDelete = false;
            msg.createdDate = moment(d).format('dddd, MMM DD YYYY');
            msg.createdDateOnly = moment(d).format('dddd, MMM DD YYYY');
            msg.createdTimeOnly = moment(d).format('hh:mma');
            msg.updatedDate = ""
            msg.senderName = "";

            //console.log(msg);
            this.chatGroupService.chatGroupMessages.push(msg);
            this.chatGroupService.ChatGroupMessageCreate(this.selectedGroup.chatGroupId, data.fileName, data.saveFileName);
            if (!(typeof this.inputGroupmessage === "undefined")) {
              this.inputGroupmessage.nativeElement.focus();
            }
          }
          //console.log(data.fileName);
          //console.log(data.saveFileName);
        }
      }),
      (data => {
        //console.log(data);
      })
      ;
  }
  checkGroupMesageType(): boolean {
    this.chatGroupService.setFortyping(this.selectedGroup.chatGroupId, this.groupmessage);
    //console.log(" othere",this.chatGroupService.activeGroupWindows.filter(x => ((x.chatGroupId == this.selectedGroup.chatGroupId) && (x.senderId != this.userData.userId) && (x.typingFlag))));
    //console.log("all ",this.chatGroupService.activeGroupWindows.filter(x => ((x.chatGroupId == this.selectedGroup.chatGroupId))));
    if (!(typeof this.chatGroupService.activeGroupWindows === "undefined")) {
      if (this.chatGroupService.activeGroupWindows.filter(x => ((x.chatGroupId == this.selectedGroup.chatGroupId) && (x.senderId != this.userData.userId) && (x.typingFlag))).length > 0) {
        return true;
      }
      else { return false; }
    }
    else { return false; }
  }
  checkMesageType(): boolean {

    this.chatService.setFortyping(this.receiverUser[0].userID, this.message);
    if (this.chatService.activeWindows.filter(x => ((x.senderId == this.receiverUser[0].userID) && (x.receiverId = this.userData.userId) && (x.typingFlag))).length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  async onKeyUpAutoSize($event: any) {
    //console.log(this.message.length == 41);
   //console.log($event);
    //console.log("validateItem", this.validateItem);
    this.checkMeneuchoice($event);
    
    if (this.ChatMode) {
      if ($event.ctrlKey && $event.key === 'Enter') {
        this.message += '\n';
      }
      
      if (this.message.length > 1) {
        if ($event.key == 'Backspace') {
          this.message = this.inputMessageBackSpace(this.message);
        }
        else {
          this.message = this.inputMessageKeywordUpdate(this.message);
        }
      }
      //console.log(this.message.includes('\n'));
      this.validateInput(this.message);
      //console.log("Message ", this.message);
      if (this.message.includes('\n')) {
        //console.log('start');
        this.heightFlag = false;
      }
      else {
        if (this.message.length > 40) {
          this.heightFlag = false;
        }
        else { this.heightFlag = true; }
      }
    }
    else {
      if ($event.ctrlKey && $event.key === 'Enter') {
        this.groupmessage += '\n';
      }
      if (this.groupmessage.length > 1) {
        if ($event.key == 'Backspace') {
          this.groupmessage = this.inputMessageBackSpace(this.groupmessage);
        }
        else {
          this.groupmessage = this.inputMessageKeywordUpdate(this.groupmessage);
        }
      }
      this.validateInput(this.groupmessage);
      if (this.groupmessage.includes('\n')) {
        this.heightFlag = false;
        //console.log('start');
      }
      else {
        if (this.groupmessage.length > 40) {
          this.heightFlag = false;
        }
        else { this.heightFlag = true; }
      }
    }

    //console.log(this.heightFlag);
  }
  inputMessageKeywordUpdate(msg: string): string {
    if (msg.substr(msg.length - 2, 2).toLowerCase() == '#s') {
      msg = msg.substr(0, msg.length - 2) + '#SKU ';
    }
    if (msg.substr(msg.length - 2, 2).toLowerCase() == '#o') {
      msg = msg.substr(0, msg.length - 2) + '#OrderId ';
    }
    if (msg.substr(msg.length - 2, 2).toLowerCase() == '#t') {
      msg = msg.substr(0, msg.length - 2) + '#Ticket ';
    }
    return msg;
  }
  inputMessageBackSpace(msg: string): string {
    if (msg.substr(msg.length - 3, 3).toLowerCase() == '#sk') {
      msg= msg.substr(0, msg.length - 2);
    }
    if (msg.substr(msg.length - 7, 7).toLowerCase() == '#orderi') {
      msg = msg.substr(0, msg.length - 6);
    }
    if (msg.substr(msg.length - 6, 6).toLowerCase() == '#ticke') {
      msg = msg.substr(0, msg.length - 5);
    }
    return msg;
  }
  checkMeneuchoice($event: any) {
    if ($event.key == '#') {
      this.menuMessageChoiceOpen($event);
    }
    //if (this.menuMessagechoiceflag != -1) {
    //  if ($event.key == ' ') {
    //    this.menuMessagechoiceflag = -1
    //    this.menuMessageKeyword = "";
    //  }
    //  else {
    //    this.menuMessageKeyword = this.menuMessageKeyword + $event.key;
    //    this.getMenuChoiceDetails();
    //  }
    //}
    //else {
    //  if ($event.key == '#') {
    //    this.menuMessageChoiceOpen();
    //  }
    //}
  }

  public menuMessageChoiceItems: MenuItemModel[] = [
    {
      text: 'SKU',
      iconCss: ''
    },
    {
      text: 'OrderId',
      iconCss: ''
    },
    {
      text: 'Ticket',
      iconCss: ''
    }];

  menuMessagechoiceflag: number = -1;
  menuMessageChoice: string = "";
  menuMessageKeyword: string = "";
  menuMessageChoiceOpen($event: any) {
    //console.log(window.scrollY - this.margin);
    //console.log(window.scrollbars.visible);
    //console.log(window.document.body.offsetHeight);
    //console.log($event.target);
    //console.log($event.target.clientHeight);
    //console.log(this.inputMessage.nativeElement.top);
    let contextmenuObj: ContextMenu = getInstance(document.getElementById("contextMenuMessageChoice"), ContextMenu) as ContextMenu;
    //window.scroll(0, 0);
    if (this.ChatMode) {
      //console.log("window.innerHeight", window.innerHeight);
      //console.log("window.screen.height", window.screen.height);
      //console.log("document.body.offsetHeight", document.body.offsetHeight);

      //console.log("document.body.clientHeight", document.body.clientHeight);
      //console.log("document.body.scrollHeight", document.body.scrollHeight);
      //console.log(this.inputMessage.nativeElement);
      //console.log(this.inputMessage.nativeElement.Parent);
      //console.log(this.inputMessage.nativeElement.offsetParent.offsetTop);
      //var top: number = (this.inputMessage.nativeElement.offsetTop + this.inputMessage.nativeElement.offsetParent.offsetTop) * 0.79;
      ///console.log(top);
      let chatMessageFooter: HTMLDivElement = document.getElementById("chatMessageFooter") as HTMLDivElement;//
      //console.log(chatMessageFooter.offsetTop);
      
      if (this.heightFlag) {
        contextmenuObj.open(window.scrollY - this.margin +(window.innerHeight * 0.79), this.inputMessage.nativeElement.offsetLeft + this.inputMessage.nativeElement.offsetParent.offsetLeft);
        //contextmenuObj.open(top, this.inputMessage.nativeElement.offsetLeft + this.inputMessage.nativeElement.offsetParent.offsetLeft);//
        //contextmenuObj.open($event.target.clientHeight, this.inputMessage.nativeElement.offsetLeft + this.inputMessage.nativeElement.offsetParent.offsetLeft);
      }
      else {
        contextmenuObj.open(window.scrollY - this.margin+(window.innerHeight * 0.69), this.inputMessage.nativeElement.offsetLeft + this.inputMessage.nativeElement.offsetParent.offsetLeft);
      }
      //contextmenuObj.open(null, null, chatMessageFooter);
    }
    else {
      let chatGroupMessageFooter: HTMLDivElement = document.getElementById("chatMessageFooter") as HTMLDivElement;
      //contextmenuObj.open(this.inputGroupmessage.nativeElement.offsetTop + this.inputGroupmessage.nativeElement.offsetParent.offsetTop, this.inputGroupmessage.nativeElement.offsetLeft + this.inputGroupmessage.nativeElement.offsetParent.offsetLeft);
      if (this.heightFlag) {
        contextmenuObj.open(window.scrollY - this.margin+(window.innerHeight * 0.79), this.inputGroupmessage.nativeElement.offsetLeft + this.inputGroupmessage.nativeElement.offsetParent.offsetLeft);
      }
      else {
        contextmenuObj.open(window.scrollY - this.margin+(window.innerHeight * 0.69), this.inputGroupmessage.nativeElement.offsetLeft + this.inputGroupmessage.nativeElement.offsetParent.offsetLeft);
      }

    }
  }
  itemMessageChoiceSelect(args: MenuEventArgs) {
    this.menuMessageKeyword = "";
    
    if (this.ChatMode) {
      if (!(typeof this.inputMessage === "undefined")) {
        this.inputMessage.nativeElement.focus();
      }
      this.message = this.message + args.item.text + ' ';
    }
    else {
      if (!(typeof this.inputGroupmessage === "undefined")) {
        this.inputGroupmessage.nativeElement.focus();
      }
      this.groupmessage = this.groupmessage + args.item.text + ' ';
    }
    //if (args.item.text == 'SKU') {
      
    //  this.menuMessagechoiceflag = 0;
    //}
    //if (args.item.text == 'OrderId') {

    //  this.menuMessagechoiceflag = 1;
    //}
    //console.log("menuMessagechoiceflag ", this.menuMessagechoiceflag);
  }
  validateItem: any[] = [];
  async validateInput(inputMessage:string) {
    //let inputMessage: string = this.message;
    this.validateItem = [];
    await this.checkKeyWord(0, inputMessage);
    await this.checkKeyWord(1, inputMessage);
    await this.checkKeyWord(2, inputMessage);
  }
  async checkKeyWord(flag: number, inputMessage: string) {
    var Keyword: string = "";
    if (flag == 0) { Keyword = '#SKU'; }
    if (flag == 1) { Keyword = '#OrderId'; }
    if (flag == 2) { Keyword = '#Ticket'; }
    if (inputMessage.indexOf(Keyword) > -1) {
      //console.log(inputMessage);
      var arr = inputMessage.split(Keyword);
      //console.log(arr.length);
      let arrRespomnse:any[] = [];
      for (let n = 1; n < arr.length; ++n) {
        let temp: string = arr[n].trim();
        if (temp != '') {
          let id = temp.split(" ")[0];
          //console.log("orderId ", id);
          if (this.validateItem.filter(x => ((x.id == id) && (x.flag == flag) )).length == 0) {
            var item: any;
            item = { 'id': id, 'flag': flag, 'validate': -1 };
            this.validateItem.push(item);
            //console.log(this.validateItem);
            let res=await this.httpService.get('api/Chat/GetChatMessageValidate/' + id + '/' + flag)
              .subscribe(
                data => {
                  //this.chatGroupIds = <any[]>data;
                  //console.log('this.validateItem ', this.validateItem);
                  //console.log('this.validateItem ', this.validateItem.find(x => ((x.id == id) && (x.flag == flag))));
                  if (data == 1) {

                    //console.log(' 1 ',this.validateItem.find(x => ((x.id == id) && (x.flag == flag))));
                    if (this.validateItem.filter(x => ((x.id == id) && (x.flag == flag))).length > 0) {
                      //console.log(' 2 ',this.validateItem.find(x => ((x.id == id) && (x.flag == flag))));

                      this.validateItem.find(x => ((x.id == id) && (x.flag == flag))).validate = 1;
                    }
                  }
                  else {
                    if (this.validateItem.filter(x => ((x.id == id) && (x.flag == flag))).length > 0) {
                      this.validateItem.find(x => ((x.id == id) && (x.flag == flag))).validate = 0;
                    }
                  }
                  
                  //console.log(n, this.validateItem);
                },
                error => {
                  //console.log('error');
                }
            );
            arrRespomnse.push(res);
          }
        }
      }
    }
  }
  async checkInput() {
    if (this.validateItem.length > 0) {
      let validateItem:any=await this.checkInputItem();

      if (validateItem.filter(x => x.validate == 0).length > 0) {return false;}
      if (validateItem.filter(x => x.validate == 1).length > 0) {return true;}
    }
    else { return true; }
  
  }
  async checkInputItem() {
    
    for (let n = 0; n < this.validateItem.filter(x=> (x.validate==-1)).length; ++n) {
      var id: string = this.validateItem[n].id;
      var flag: string = this.validateItem[n].flag;
      var data = await this.callInputItemURL('api/Chat/GetChatMessageValidate/' + id + '/' + flag);
      
      if (data == '1') {
        if (this.validateItem.filter(x => ((x.id == id) && (x.flag == flag))).length > 0) {
          this.validateItem.find(x => ((x.id == id) && (x.flag == flag))).validate = 1;
        }
      }
      else {
        if (this.validateItem.filter(x => ((x.id == id) && (x.flag == flag))).length > 0) {
          this.validateItem.find(x => ((x.id == id) && (x.flag == flag))).validate = 0;
        }
      }
            

    }
    return this.validateItem;
  }
  async callInputItemURL(url: string)
  {
    return await this.httpService.get(url).toPromise();
     
  }
  returnInvalidId():any[] {
    return this.validateItem.filter(x => (
      x.validate==0));
  }
  focusFlag: boolean=false;
  unfocusChat(flag: boolean) {
    this.focusFlag = flag;
    //console.log('this.focusFlag ', this.focusFlag);
    //this.setChatMessageRead();
  }
  //getMenuChoiceDetails() {
  //  if (this.menuMessagechoiceflag != -1) {
  //    //console.log("Keyword ",this.menuMessageKeyword);
  //  }
  //}
}
export class ChatUser {

  userID: number;
  userName: string;
  isLogged: Boolean;
  unReadMessage: number
}





