import { Injectable } from '@angular/core';
import {BehaviorSubject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class BehaviourSubjectServiceService {

  constructor() { }
  private profileImage = new BehaviorSubject<any>(null);

  data$ = this.profileImage.asObservable();
  private Values= new BehaviorSubject<string>('');
  CastValues = this.Values.asObservable();

  sendMessage(newValues) {
    this.Values.next(newValues);
  }

  setData(data: any) {
    this.profileImage.next(data);
  }

  // Method to get the current data value
  getData() {
    return this.profileImage.value;
  }
}
