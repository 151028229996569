import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { CookieService } from 'ngx-cookie-service';
import { setInterval } from 'timers';
import { HttpClientService } from '../../services/http.client.service';
import { BackEndUrlService } from '../../services/backend.url.service';
import { AuthService } from '../../services/auth.service';
import { userModel } from '../../Model/userDetails';
@Injectable()
export class ChatGroupService {
  connectionEstablished = new EventEmitter<Boolean>();

  public connectionIsEstablished = false;
  private hubConnection: signalR.HubConnection;
  messages: string[] = [];
  //userDetail: loggedUser;
  public chatUsers: any[];
  public chatGroupDetails: any[];
  public chatGroupMessages: any[];
  public groupMessageUnreadCount: any[];
  public activeGroupWindows: any[];
  public messageAll: any[];
  public lastChatGroupMessage: any[] = [];
  login: any;
  public userData = new userModel();
  userDataSubscription: any;

  //chatMessageInputParameter:ChatMessageInputParameter;
  constructor(
    private backEndUrlService: BackEndUrlService,
    private cookieService: CookieService, private authService: AuthService
  ) {
    //this.login = JSON.parse(localStorage.getItem("UserInfo"));
    this.authService.setUserDetails();
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      //console.log("this.userData ", this.userData)
    });
    //console.log(this.login);
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
    
    //setInterval (    this.test(),50000);  
  }
  private createConnection() {
    
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(this.backEndUrlService.backendUrl+"ChatGroupHub")
      .build();
    //this.hubConnection = new signalR.HubConnectionBuilder()
    //  .configureLogging(signalR.LogLevel.Debug)
    //  .withUrl("http://test.utilities.tagsweekly.com/ChatGroupHub")
    //  .build();
  }
  private startConnection(): void {
    this.connectionIsEstablished = false;
    this.hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        //console.log('connected...');
        this.connectionEstablished.emit(true);
        this.UserConnection();
        
      })
      .catch(err => {
        //console.log(err);
        //console.log('Error while establishing chat group connection, retrying...');
        setTimeout(function () { this.startConnection(); }, 1000);
      });
    this.hubConnection.onclose(() => {
      //console.log('chat Group Connection Close');
      setTimeout(function () {
        this.startConnection();

      }, 1000);
    });
  }
  public UserConnection() {
    //console.log('invoke group start...', this.userData.userId);
    this.hubConnection.invoke('Connect', this.userData.userId);
    //console.log('invoke...');
  }
  public getLastGroupChatMessage() {

    this.hubConnection.invoke('getLastChatGroupMessages');
  }
  public SetActiveGroupWindow(ChatGroupId:number) {
    this.hubConnection.invoke('SetActiveGroupWindow', this.userData.userId, ChatGroupId);
  }
  public ClearActiveGroupWindow() {
    this.hubConnection.invoke('ClearActiveGroupWindow', this.userData.userId);
  }
  public GetChatGroupDetails(): void {
    this.hubConnection.invoke('GetChatGroupDetails');
  }
  public GetChatGroupMessageByChatGroupId(ChatGroupId: number): void {
    //console.log("start GetChatGroupMessageByChatGroupId ", ChatGroupId);
    this.hubConnection.invoke('GetChatGroupMessageByChatGroupId', ChatGroupId);
  }
  public ChatGroupAddUser(ChatGroupId: number, UserId: number): void {
    //console.log("ChatGroupId", ChatGroupId);
    //console.log("UserId", UserId);
    this.hubConnection.invoke('ChatGroupAddUser', ChatGroupId, UserId);
  }
  public ChatGroupMessageCreate(ChatGroupId: number, Message: string, SaveFileName: string): void {
    this.hubConnection.invoke('ChatGroupMessageCreate', ChatGroupId, Message, SaveFileName, this.userData.userId);
  }
  public ChatGroupMessageUpdate(ChatGroupMessageId: number, Message: string) {
    //console.log('ChatGroupMessageUpdate');
    this.hubConnection.invoke('ChatGroupMessageUpdate', ChatGroupMessageId, Message);
    //console.log('ChatGroupMessageUpdate end');
  }
  public ChatGroupMessageDelete(ChatGroupMessageId: number) {
    //console.log('ChatMessageDelete ', ChatGroupMessageId);
    this.hubConnection.invoke('ChatGroupMessageDelete', ChatGroupMessageId);
    //console.log('ChatMessageDelete end');
  }
  public SetChatGroupMessageAWSeen(chatGroupId: number) {
    //console.log('AW start ', this.groupMessageUnreadCount);
    //console.log(chatGroupId);
    //console.log('ChatMessageDelete ', this.groupMessageUnreadCount.filter(x => ((x.chatGroupId == chatGroupId) && (x.userId == this.userData.userId))).length);
    var AW = this.activeGroupWindows.filter(x => ((x.chatGroupId == chatGroupId) && (x.senderId != this.userData.userId)));
    //console.log('AW ', AW);
    if (AW.length != 0) {
      for (let n = 0; n < AW.length; ++n) {
        this.hubConnection.invoke('SetChatGroupMessageSeen', AW[n].chatGroupId, AW[n].senderId);
      }
    }
    //console.log('ChatMessageDelete end');
  }
  public SetChatGroupMessageSeen(chatGroupId: number) {
    //console.log('ChatMessageDelete ', this.groupMessageUnreadCount);
    //console.log(chatGroupId);
    //console.log('ChatMessageDelete ', this.groupMessageUnreadCount.filter(x => ((x.chatGroupId == chatGroupId) && (x.userId == this.userData.userId))).length);
    if (!(typeof this.groupMessageUnreadCount === "undefined")) {
      if (this.groupMessageUnreadCount.filter(x => ((x.chatGroupId == chatGroupId) && (x.userId == this.userData.userId))).length != 0) {
        this.hubConnection.invoke('SetChatGroupMessageSeen', chatGroupId, this.userData.userId);
      }
    }
    //console.log('ChatMessageDelete end');
  }
  public GetChatGroupMessageUnreadCount() {
    this.hubConnection.invoke('GetChatGroupMessageUnreadCount');
  }
  setFortyping(chatGroupId: number, groupmessage:string) {
    if (groupmessage.trim() != "") {
      //console.log("groupmessage ", this.groupmessage);
      //console.log(this.chatGroupService.activeGroupWindows);
      //console.log(this.chatGroupService.activeGroupWindows.filter(x => ((x.chatGroupId == this.selectedGroup.chatGroupId) && (x.senderId == this.userData.userId))));
      let item = this.activeGroupWindows.filter(x => ((x.chatGroupId == chatGroupId) && (x.senderId == this.userData.userId)));
      //console.log(item);
      if (item.length > 0) {
        if (!item[0].typingFlag) {
          
          item[0].typingFlag = true;
          let itemIndex = this.activeGroupWindows.indexOf(x => ((x.chatGroupId == chatGroupId) && (x.senderId == this.userData.userId)));
          if (itemIndex != -1) {
            this.activeGroupWindows[itemIndex] = item[0];
          }
          this.hubConnection.invoke('SetActiveGroupWindowForTyping', this.userData.userId, chatGroupId,true);
          //console.log(item[0]);

          // //console.log("itemIndex 1", itemIndex);
        }
      }
    }
    else {
      
        if (!(typeof this.activeGroupWindows === "undefined")) {
        let item = this.activeGroupWindows.filter(x => ((x.chatGroupId == chatGroupId) && (x.senderId == this.userData.userId)));
        //console.log(item);
        if (item.length > 0) {
          //console.log(item[0]);
          if (item[0].typingFlag) {

            item[0].typingFlag = false;
            let itemIndex = this.activeGroupWindows.indexOf(x => ((x.chatGroupId == chatGroupId) && (x.senderId == this.userData.userId)));

            if (itemIndex != -1) {
              this.activeGroupWindows[itemIndex] = item[0];
            }
            this.hubConnection.invoke('SetActiveGroupWindowForTyping', this.userData.userId, chatGroupId, false);

          }
        }
      }
    }

    //console.log(this.activeGroupWindows);

  }
  private registerOnServerEvents(): void {
    this.hubConnection.on('LoggedUser', (data: any) => {
      //console.log("Group LoggedUser ", data);
      this.chatUsers = <any>data;
      this.GetChatGroupDetails();
      this.GetChatGroupMessageUnreadCount();
      this.getLastGroupChatMessage();
      //console.log("Group LoggedUser",this.chatUsers);
    });
    this.hubConnection.on('ChatGroupDetails', (data: any) => {
      this.chatGroupDetails = <any>data;
      
      //console.log("ChatGroupDetails", data);
    });
    this.hubConnection.on('ChatGroupMessages', (data: any) => {
      this.chatGroupMessages = <any>data;
      for (let n = 0; n < this.chatGroupMessages.length; ++n) {
        this.chatGroupMessages[n].updateMessage = '';// this.messageAll[n].message;
      }
      this.GetChatGroupMessageUnreadCount();
      this.getLastGroupChatMessage();
      //console.log("ChatGroupMessages", data);
    });
    this.hubConnection.on('ChatGroupMessageUnreadCount', (data: any) => {
      this.groupMessageUnreadCount = <any>data;

      //console.log("ChatGroupMessageUnreadCount", data);
    });
    this.hubConnection.on('ActiveGroupWindows', (data: any) => {
      this.activeGroupWindows = <any>data;

      //console.log("ActiveGroupWindows", data);
    });

    this.hubConnection.on('LastGroupMessageAll', (data: any) => {
      //this.getChatMessageUnreadCount();
      this.lastChatGroupMessage = <any>data;
      //console.log("LastGroupMessageAll", data);
    });
  }
  }
export class ChatGroupMessage {
  public chatGroupMessageId: number;
  public chatGroupId: number;
  public message: string;
  public updateMessage: string;
  public saveFileName: string;
  public senderId: number;
  public isDelete: boolean;
  public createdDate: string;
  public createdDateOnly: string;
  public createdTimeOnly: string;
  public updatedDate: string;
  public senderName: string;
  
  constructor() { }
}



