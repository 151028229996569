import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AdminComponent } from './admin.component';
import { AdminRouting } from './admin.routing';
import { AvatarModule } from 'ngx-avatar'
import { AutosizeModule } from 'ngx-autosize';
import { HttpClientService } from '../services/http.client.service';
import { BackEndUrlService } from '../services/backend.url.service';
import { TaskSetting } from './task/taskSetting';
import { ChatComponent } from './chat/window/chat.component';
import { ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { ChatService } from './chat/chat.service';
import { ChatGroupService } from './chat/chatgroup.service';
import { ScrollToBottomDirective } from './chat/scroll-to-bottom.directive';
import { DatePipe } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FocusDirective } from './../directive/FocusDirective.directive';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { IdleExpiry, LocalStorageExpiry } from '@ng-idle/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UrlService } from '../services/url.service';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { TooltipModule as PrimeTooltip } from 'primeng/tooltip';
import { CustomFilterModule } from '../Common/custom-filter.module';
import { InputDirective } from '../directive/input.directive';
import { ProductCATestingComponent } from './product-catesting/product-catesting.component';
import { ToastModule } from 'primeng/toast';


@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, AdminRouting, RouterModule, MessageModule, MessagesModule, TooltipModule, PrimeTooltip,
    AvatarModule, ContextMenuModule, AutosizeModule, ProgressSpinnerModule, DropdownModule, NgIdleKeepaliveModule.forRoot(), ModalModule.forRoot(), CustomFilterModule, ToastModule
  ],
  declarations: [AdminComponent, ChatComponent, InputDirective, ProductCATestingComponent,/*, DndDirective*/],
  exports: [
    RouterModule, MessageModule, MessagesModule, AdminComponent
  ],
  providers: [HttpClientService, BackEndUrlService, TaskSetting, ChatService, UrlService, ChatGroupService, ScrollToBottomDirective, DatePipe, FocusDirective
    , {
      provide: IdleExpiry,
      useClass: LocalStorageExpiry
    }
  ]
})

export class AdminModule { }
