import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { CustomFilter } from "./custom-filter";
import { RemoveBlankspacePipe } from './remove-blankspace.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  imports: [],
  declarations: [CustomFilter, RemoveBlankspacePipe, SafePipe ],
  exports: [CustomFilter, RemoveBlankspacePipe, SafePipe]
})

export class CustomFilterModule { }
