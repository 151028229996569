import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/not-found.component';
import { log } from 'util';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },

  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: 'forgot/:type',
    loadChildren: './forgot/forgot.module#ForgotModule'
  },
  {
    path: 'ChangePassword',
    loadChildren:'./changepassword/changepassword.module#ChangePasswordModule'
  }
  //,

  //{
  //  path: '**',
  //  component: NotFoundComponent
  //}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
