import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { CookieService } from 'ngx-cookie-service';

import { setInterval } from 'timers';
import { HttpClientService } from '../../services/http.client.service';
import { BackEndUrlService } from '../../services/backend.url.service';
import { AuthService } from '../../services/auth.service';
import { userModel } from '../../Model/userDetails';
@Injectable()
export class ChatService {
  connectionEstablished = new EventEmitter<Boolean>();

  public connectionIsEstablished = false;
  private hubConnection: signalR.HubConnection;  
  messages: string[] = [];
  userDetail: loggedUser;
  public chatUsers: any[];
  public messageUnreadCount: any[];
  public activeWindows: any[];
  public messageAll: any[];
  public lastMessageAll: any[];
  login: any;

  public userData = new userModel();
  userDataSubscription: any;

  chatMessageInputParameter:ChatMessageInputParameter;
  constructor(
    private backEndUrlService: BackEndUrlService,
    private cookieService: CookieService,
    private authService: AuthService
  ) {
    this.login = JSON.parse(localStorage.getItem("UserInfo"));
    this.authService.setUserDetails();
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      //console.log("this.userData ", this.userData)
    });
    //console.log(this.login);
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
    
    //setInterval (    this.test(),50000);
    //console.log("this.backEndUrlService.backendUrl :",this.backEndUrlService.backendUrl);
  }
  private createConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(this.backEndUrlService.backendUrl+"ChatHub")
      .build();
    //this.hubConnection = new signalR.HubConnectionBuilder()
    //  .configureLogging(signalR.LogLevel.Debug)
    //  .withUrl("http://test.utilities.tagsweekly.com/ChatHub")
    //  .build();
  }
  public UserConnection() {
    //console.log('invoke chat start...', this.userData.userId);
    this.hubConnection.invoke('Connect', this.userData.userId);
   //console.log('invoke...');
  }
  private startConnection(): void {
    
    this.hubConnection
      .start()
      .then(() => {
        
        this.UserConnection();
        this.connectionIsEstablished = true;
        //console.log('connected...');
        this.connectionEstablished.emit(true);
      })
      .catch(err => {
        //console.log(err);
        //console.log('Error while establishing chat connection, retrying...');
        setTimeout(function () { this.startConnection(); }, 1000);
      });
    this.hubConnection.onclose(() => {
      //console.log('chat Connecttion Close');
      setTimeout(function () {
        this.startConnection();
      }, 3000);
    }); 
  }
  public getChatMessageUnreadCount() {
    this.hubConnection.invoke('getChatMessageUnreadCount');
  }
  
  public SetActiveWindow(receiverUser:any[]) {
    this.hubConnection.invoke('SetActiveWindow',  this.userData.userId, receiverUser[0].userID);
  }
  public ClearActiveWindow() {
    this.hubConnection.invoke('ClearActiveWindow', this.userData.userId);
  }
  public getLastChatMessage() {
    this.hubConnection.invoke('getLastChatMessage');
  }
  public  getChatMessage(receiverUser: any[]) {
     this.hubConnection.invoke('getChatMessage',  this.userData.userId, receiverUser[0].userID);
  }
  public  setChatMessageRead(receiverUser: any[]) {
    //console.log('setChatMessageRead');
     this.hubConnection.invoke('setChatMessageRead', receiverUser[0].userID, this.userData.userId);
    //console.log('setChatMessageRead end');
  }
  public  ChatMessageUpdate(ChatMessageId: number, Message: string) {
    //console.log('ChatMessageUpdate');
     this.hubConnection.invoke('ChatMessageUpdate', ChatMessageId, Message);
    //console.log('ChatMessageUpdate end');
  }
  public  ChatMessageDelete(ChatMessageId: number) {
    //console.log('ChatMessageDelete ', ChatMessageId);
     this.hubConnection.invoke('ChatMessageDelete', ChatMessageId);
    //console.log('ChatMessageDelete end');
  }
  public ChatMessageCreate(message: string, SaveFileName:string, receiverUser: any[]) {
    //console.log('start');
    this.hubConnection.invoke('ChatMessageCreate', message,SaveFileName, this.userData.userId, receiverUser[0].userID);
    //console.log('end'); 

    
    //console.log('chat service');
  }
  setFortyping(receiverId: number, message: string) {
    if (message.trim() != "") {
      //console.log("groupmessage ", this.groupmessage);
      //console.log(this.activeWindows);
      //console.log(this.chatGroupService.activeGroupWindows.filter(x => ((x.chatGroupId == this.selectedGroup.chatGroupId) && (x.senderId == this.userData.userId))));
      let item = this.activeWindows.filter(x => ((x.receiverId == receiverId) && (x.senderId == this.userData.userId)));
      //console.log(item);
      if (item.length > 0) {
        if (!item[0].typingFlag) {

          item[0].typingFlag = true;
          let itemIndex = this.activeWindows.indexOf(x => ((x.receiverId == receiverId) && (x.senderId == this.userData.userId)));
          if (itemIndex != -1) {
            this.activeWindows[itemIndex] = item[0];
          }
          this.hubConnection.invoke('SetActiveWindowForTyping', this.userData.userId, receiverId, true);
          //console.log("true ",item[0]);

          // //console.log("itemIndex 1", itemIndex);
        }
      }
    }
    else {
      if (this.activeWindows.length > 0) {
        let item = this.activeWindows.filter(x => ((x.receiverId == receiverId) && (x.senderId == this.userData.userId)));
        //console.log(item);
        if (item.length > 0) {
          //console.log(item[0]);
          if (item[0].typingFlag) {
            //console.log("false ",item[0]);
            item[0].typingFlag = false;
            let itemIndex = this.activeWindows.indexOf(x => ((x.receiverId == receiverId) && (x.senderId == this.userData.userId)));

            if (itemIndex != -1) {
              this.activeWindows[itemIndex] = item[0];
            }
            this.hubConnection.invoke('SetActiveWindowForTyping', this.userData.userId, receiverId, false);

          }
        }
      }
    }

    //console.log(this.activeGroupWindows);

  }

  private registerOnServerEvents(): void {
    this.hubConnection.on('LoggedUser', (data: string) => {
      //console.log("LoggedUser ", data);
      this.chatUsers = <any>data;
      this.getChatMessageUnreadCount();
      this.getLastChatMessage();
      
    });
    this.hubConnection.on('ActiveWindows', (data: any) => {
      this.activeWindows = <any>data;

      //console.log("ActiveWindows",data);
    });
    this.hubConnection.on('setChatMessageReadEnd', (data: any) => {
      //console.log("setChatMessageReadstart");
      this.messageUnreadCount = <any>data;
      //console.log("setChatMessageReadEnd", data);
    });
    this.hubConnection.on('ChatMessageUnreadCount', (data: any) => {
      this.messageUnreadCount = <any>data;
      //console.log("ChatMessageUnreadCount",data);
    });
    this.hubConnection.on('MessageAll', (data: any) => {
      //this.getChatMessageUnreadCount();
      
      this.messageAll = <any>data;
      for (let n = 0; n < this.messageAll.length; ++n) {
        this.messageAll[n].updateMessage = '';// this.messageAll[n].message;
      }
      //console.log("messageAll", this.messageAll);
      this.getChatMessageUnreadCount();
      this.getLastChatMessage();
    });
    this.hubConnection.on('LastMessageAll', (data: any) => {
      //this.getChatMessageUnreadCount();
      this.lastMessageAll = <any>data;
      //console.log("lastmessageAll", data);
    });
  }
}
export class loggedUser {
  connectionId: string;
  userID: number;
  //userName: string;
  //isLogged: Boolean;
  //      nReadMessage :number
}
export class ChatMessageInputParameter {
  Message: string;
  SenderId: number;
  ReceiverId: number;
}
export class ChatMessage {
  public chatMessageId: number;
  public message: string;
  public updateMessage: string='';
  public saveFileName: string;
  public senderId: number;
  public receiverId: number
  public isSeen: boolean;
  public isDelete: boolean;
  public createdDate: string;
  public createdDateOnly: string;
  public createdTimeOnly: string;
  public updatedDate: string;
  public senderName: string;
  public receiverName: string;
  public roomName: string;
  public seenDate: string;
  constructor() {}
    }


