//@ts-ignore
import { Injectable } from '@angular/core';
//@ts-ignore
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BackEndUrlService } from './backend.url.service';
import { map } from 'jquery';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
//@ts-ignore
export class HttpClientService {
  public loading = true;
  constructor(private httpCl: HttpClient, private _backEndUrlService: BackEndUrlService) { }
  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Private-Network': 'true', 'Access-Control-Request-Private-Network': 'true' });
  backendUrlService: string = this._backEndUrlService.backendUrl;
  getwithparam(url: any, data: any) {
    // return this.httpCl.get(this.backendUrlService + url + "?" + data);
    return this.httpCl.get(url + "?" + data);
  }
  get(url: any) {
    //return this.httpCl.get(this.backendUrlService + url);
    return this.httpCl.get(url);
  }
  post(url: any, data: any) {
    //return this.httpCl.post(this.backendUrlService + url, data);
    return this.httpCl.post(url, data);
  }
  postwithparam(url: any, data: any) {
    //return this.httpCl.post(this.backendUrlService + url, data);
    return this.httpCl.post(url, data);
  }
  postwithdata(url: any, data: any, datatype: any) {
    // return this.httpCl.post(this.backendUrlService + url, data, datatype);
    return this.httpCl.post(url, data, datatype);
  }

  postnew(url: any, data: any,) {
    return this.httpCl.post<any>(url, data, { headers: this.headers });
  }

  getnew(url: any) {
    return this.httpCl.get<any>(url, { headers: this.headers });
  }
  delete(url: any) {
    return this.httpCl.delete<any>(url, { headers: this.headers });
  }
  head(url: string) {
    const headers = this.headers
    return this.httpCl.head(url, { observe: 'response', headers: headers });
  }
}
