import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { HttpClientService } from '../../services//http.client.service';
import { MessageService } from 'primeng/components/common/messageservice';

@Component({
  selector: 'app-product-catesting',
  templateUrl: './product-catesting.component.html',
  styleUrls: ['./product-catesting.component.css']
})
export class ProductCATestingComponent implements OnInit, OnDestroy {
  @ViewChild('ConfirmModal', { static: false }) ConfirmModal: ModalDirective;
  sku: string = '';
  images:string[]=new Array<string>();
  qty: number = 0;
  pickedqty: number = 0;
  UnshippedQty: number = 0;
  ProductId: number = 0;
  constructor(private httpservice: HttpClientService, private messageService: MessageService) {

   }

  ngOnInit() {
    setTimeout(() => {
      this.openConfirmationModel();
    }, 1000)
  }
  ngOnDestroy() {
    this.closeConfirmModal();
  }
  openConfirmationModel() {
    this.ConfirmModal.show();
  }
  closeConfirmModal() {
    this.ConfirmModal.hide();
  }
  updateQty() {
    debugger
    var updatedQty: number = 0;
    updatedQty = Number(this.qty) - (Number(this.UnshippedQty) -Number(this.pickedqty));
    this.httpservice.get(`api/CAProduct/updateQty/${updatedQty}?sku=${this.sku}`).subscribe((res:any)=>{
      if(res.Status==200||res.status==200)
      this.messageService.add({ severity: 'success', summary: 'Quantity successfully uploaded', detail: '' });
    else
    {
      this.messageService.add({ severity: 'error', summary: res.Message||res.message, detail: '' });
    }
      console.log('qty res',res)
    },error=>{
    })
  }
  // updateImages(){
  //   this.httpservice.get('api/CAProduct/updateImages?sku='+this.sku).subscribe((res: any)=>{
  //     console.log('qty res',res)
  //   },error=>{
  //   })
  // }

  updateImages() {
    this.httpservice.get('api/CAProduct/updateImages?sku=' + this.sku).subscribe((res: any) => {
      if (res.Status == 200 || res.status == 200)
        this.messageService.add({ severity: 'success', summary: 'Images successfully uploaded', detail: '' });
      else {
        this.messageService.add({ severity: 'error', summary: res.Message || res.message, detail: '' });
      }
      console.log('image res', res);
    }, error => {
      console.error(error);
    });
  }
  onChange(event){
    this.sku= event.target.value 
  }
 
  getImagesAndQty() {
   debugger
    this.httpservice.get(`api/CAProduct/getImagesAndQty?sku=${this.sku}`).subscribe((res: any) => {
     console.log('res', res);
      this.qty = res.data.quantityDto.quantity;
      this.pickedqty = res.data.quantityDto.pickQuantity;
      this.UnshippedQty = res.data.quantityDto.unshippedQty;
     this.ProductId= res.data.productId||0;
      this.images = res.data.imagedTs.map((img: any) => 'https://skuvelocity.com/assets/images/matched/' + img.imagename);
     
    }, error => {
      alert('error ' + error);
    });
  }

}
