import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, HostListener, SimpleChanges } from '@angular/core';
import { feilds } from '../../Model/dropDownModel';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'multi-select-drop-down',
  templateUrl: './multi-select-drop-down.component.html',
  styleUrls: ['./multi-select-drop-down.component.css']
})
export class MultiSelectDropDownComponent implements OnInit {
  @Input() dataList: any[] = new Array()
  @Input() feilds: feilds = <feilds>{}
  @Input() id: string = ''
  @Input() dataListCopy: any[] = []
  @Input() selectedValues: any[] = []

  isClickOnAttributeDropdown: boolean = false;
  @ViewChild('DropdownFilter', { static: false }) DropdownFilter: ElementRef;
  slectedValueShowingText: any[] = []
  @Output() onSelect = new EventEmitter<any[]>();
  @Output() clearFilter = new EventEmitter<any[]>();
  isSecrch: boolean = false
  subject = new BehaviorSubject<any[]>([]);
  showingData: number = 0
  intervalId: any
  isDDopen: boolean = false;
  constructor(private elem: ElementRef) { }
  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.setDDShowingText()
    }, 1000)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onPropesChange(changes)
  }
  onPropesChange(changes: SimpleChanges) {
    // if (changes['dataList'] && ![null, undefined].includes(changes['dataList'].currentValue)) {
    //   this.dataList = changes['dataList'].currentValue;
    // } else {
    //   this.dataList = [];
    // }
    // if (changes['dataListCopy'] && ![null, undefined].includes(changes['dataListCopy'].currentValue)) {
    //   this.dataListCopy = changes['dataListCopy'].currentValue;
    // } else {
    //   this.dataListCopy = [];
    // }
    // if (changes['selectedValues'] && ![null, undefined].includes(changes['selectedValues'].currentValue)) {
    //   this.selectedValues = changes['selectedValues'].currentValue;
    //   this.slectedValueShowingText = this.dataListCopy ? this.dataListCopy.filter(x => this.selectedValues.includes(x[this.feilds.text])).map(x => x[this.feilds.text]) : []
    // } else {
    //   this.selectedValues = [];
    // }
    // if (changes['id'] && changes['id'].currentValue) {
    //   this.id = changes['id'].currentValue;
    // } else {
    //   this.id = '';
    // }     

  }

  setDDShowingText() {
    this.slectedValueShowingText = this.getSelectedValues();
  }
  serchText($event) {
    $event.stopPropagation()
    if ($event.target.value != '') {
      this.dataList = []
      const res = this.dataListCopy.filter(s => String(s[this.feilds.text]).toLowerCase().indexOf(String($event.target.value).toLowerCase()) != -1).slice(0, 50)
      this.isSecrch = true
      this.subject.next(res)
      this.dataList = res;
      this.showingData = res.length
    }
    else {
      this.dataList = []
      this.dataList = this.dataListCopy.slice(0, 50)
      this.isSecrch = true
      this.showingData = this.dataList.length
      this.subject.next(this.dataList)
    }
  }
  clear() {
    this.DropdownFilter.nativeElement.value = '';
    this.selectedValues = [];
    this.slectedValueShowingText = [];
    this.isSecrch = false;
    if (this.isSecrch == false) {
      this.dataList = []
      this.dataList = this.dataListCopy.slice(0, 50)
    }
    this.onSelect.emit(this.selectedValues)
  }
  cleareAll(event) {
    event.stopPropagation();
    this.isSecrch = false;
    if (this.isSecrch == false) {
      this.dataList = []
      this.dataList = this.dataListCopy.slice(0, 50)
    }
    if (this.selectedValues.length == 0) {
      this.selectedValues = [];
      this.slectedValueShowingText = []
    }

    this.onSelect.emit(this.selectedValues)
  }
  selectUnSelectAll(event) {
    event.stopPropagation()
    this.selectedValues = this.selectedValues.length == this.dataListCopy.length ? [] : this.dataListCopy.map(u => u[this.feilds.value]);
    this.slectedValueShowingText = this.getSelectedValues()
    this.onSelect.emit(this.selectedValues)
  }
  onSelectUnSelect(value, $event) {
    $event.stopPropagation();
    this.selectedValues.includes(value) ? this.selectedValues.splice(this.selectedValues.indexOf(value), 1) : this.selectedValues.push(value)
    this.slectedValueShowingText = this.getSelectedValues()
    this.onSelect.emit(this.selectedValues)
  }
  getSelectedValues() {
    return this.selectedValues && this.selectedValues.length > 0 && this.dataListCopy.length > 0 ?
      this.selectedValues.map(s => this.dataListCopy.find(x => x[this.feilds.value] == s)).filter(s => s != undefined).map(u => u[this.feilds.text])
      : []
  }
  @HostListener('document:click', ['$event'])
  DocumentClick(event: Event) {
    if (!this.elem.nativeElement.contains(event.target)) {
      this.isClickOnAttributeDropdown = false;
      this.isSecrch = false;
      this.DropdownFilter.nativeElement.value = '';
      if (this.isSecrch == false) {
        this.dataList = []
        this.dataList = this.dataListCopy.slice(0, 50)
      }
    }
  }

  openDD(event) {
    this.isClickOnAttributeDropdown = !this.isClickOnAttributeDropdown;
    setTimeout(() => {
      this.DropdownFilter.nativeElement.focus();
    }, 100)
    this.OpenDropDown(event.target.id)
  }
  OpenDropDown(id) {

    var element = document.getElementById(id);
    if (element != null && element != undefined && this.isDDopen) {
      this.isDDopen = false;
      setTimeout(() => {
        this.isDDopen = true;
      }, 1000)
    }
  }
  onClickFilter(event: any) {
    event.stopPropagation();
  }
}
