// globals.ts
import { Injectable } from '@angular/core';
import { HttpClientService } from '../../services/http.client.service';
import { BackEndUrlService } from '../../services/backend.url.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { TaskSettingModel } from './taskSettingModels';
import { AuthService } from '../../services/auth.service';
import { userModel } from '../../Model/userDetails';

@Injectable()
export class TaskSetting {
  public login: any;
  public userData = new userModel();
  userDataSubscription: any;
   _TaskNotificationCount = new BehaviorSubject<TaskSettingModel>(null);
  settings$ = this._TaskNotificationCount.asObservable();

  constructor(private httpService: HttpClientService,
    private authService: AuthService
  ) {
    //this.login = JSON.parse(localStorage.getItem("UserInfo"));
    this.authService.setUserDetails();
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      console.log("userData ", this.userData);
    });
    
  }
  getTaskCountForNotification() {
    var count = "";
    setInterval(() => {
  this.httpService.get('api/Task/getTaskCountForNotification/' + this.userData.userId)
    .subscribe(res => {
      //console.log(res);
      let data = <any>res;
      
      if (data != null) {
        //if (data[0].notificationCount != "0") {
          this._TaskNotificationCount.next(data[0].notificationCount);
        //}
      
      }
    
    
        });
    }, 5000);
  }
}


