import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { userModel,userPages } from '../Model/userDetails';
import { BackEndUrlService} from '../services/backend.url.service';
import { decode } from 'punycode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userPageList = new Array<userPages>();
  userData = new BehaviorSubject<userModel>(new userModel());
  menuList = new BehaviorSubject<Array<MenuObjects>>(new Array<MenuObjects>());
  usereImage : BehaviorSubject<String>= new BehaviorSubject<string>('');

  constructor(private http: HttpClient, private router: Router, private backEndUrlService: BackEndUrlService) {
    this.setUserDetails()
   }
changeuserImage(image:string){
 debugger;
  this.usereImage.next(image)  
}
  login(userDetails) {
    return this.http.post<any>(this.backEndUrlService.backendUrl + 'Login', userDetails)
      .pipe(map(response => {
        if (response.token != "") {
          localStorage.setItem('authToken', response.token);          
          this.setUserDetails();
        }
        if (response.userDetails.userID > 0) {
          return 200;
        }
        else {
          return response.userDetails.userID;
        }
      }));
  }

  setUserDetails() {
    this.userPageList = new Array<userPages>(); 
    this.userPageList.push({ pageName: 'dashboard' }, { pageName: 'user'});
    if (localStorage.getItem('authToken')) {
      const userDetails = new userModel();
      const decodeUserDetails = JSON.parse(window.atob(localStorage.getItem('authToken').split('.')[1]));
      userDetails.userName = decodeUserDetails.sub;
      userDetails.firstName = decodeUserDetails.firstName;
      userDetails.lastName = decodeUserDetails.lastName;
      userDetails.roleName = decodeUserDetails.roleName;
      userDetails.userId = decodeUserDetails.userID;
      userDetails.isLoggedIn = true;
      userDetails.roleId = decodeUserDetails.roleId;
      userDetails.listuserPage = this.userPageList;
      userDetails.isSupervisor = decodeUserDetails.isSupervisor;
      userDetails.isAdmin = decodeUserDetails.isAdmin;
      this.getMenus(decodeUserDetails.userID);
      this.userData.next(userDetails);
    }
  }

  getMenus(userID: number) {
    var observable = this.http.get('GetMenuPrivileges' + "/" + userID);
    observable.subscribe(
      (res: any) => {
        if (res != undefined && res != null && res.length > 0) {
          this.menuList.next(res);
        }
      });
  }

  logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userName');
    this.router.navigate(['/login']);
    this.userData.next(new userModel());
    localStorage.setItem('lastUrl', '');
  }
  logoutUserName() {
    localStorage.removeItem('authToken');
   // localStorage.removeItem('userName');
    this.router.navigate(['/login']);
    this.userData.next(new userModel());
    localStorage.setItem('lastUrl', '');
  }
}

export class MenuObjects {
  formGroupId: number;
  formPath: string = '';
  formPage: string = '';
  parentHeaderName: string = '';
  headerName: string = '';
  childHeaderName: string = '';
  isShowInMenu: boolean;
  isAddAvailable: boolean;
  isEditAvailable: boolean;
  isViewAvailable: boolean;
  isDeleteAvailable: boolean;
}
