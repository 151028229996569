import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/not-found.component';

import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TreeTableModule } from 'primeng/treetable'; 
import { TableModule } from 'primeng/table';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { GrowlModule } from 'primeng/primeng';

//import { ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { ChatService } from './admin/chat/chat.service';
import { ChatGroupService } from './admin/chat/chatgroup.service';
import { ScrollToBottomDirective } from './admin/chat/scroll-to-bottom.directive';
import { DatePipe } from '@angular/common';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ConfirmationComponent } from './pages/confirmation.component'
import { confirmationProductComponent } from './pages/confirmationProduct.component'


import { MessageService } from 'primeng/api';
import { HttpInterceptorService} from './services/http-interceptor.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { BackEndUrlService } from './services/backend.url.service';
import { FocusDirective } from './directive/FocusDirective.directive'
import { ContextMenuModule } from 'ngx-contextmenu';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { AdminModule } from './admin/admin.module';
import { SharedModule } from './admin/shared/shared.module';
 


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    confirmationProductComponent
  ],
  imports: [
    AppRoutingModule, 
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' })
    
    , HttpClientModule
    ,FormsModule
    , RouterModule, BrowserAnimationsModule, TreeTableModule, TableModule,  MessageModule, MessagesModule, GrowlModule
    , ModalModule.forRoot(), ContextMenuModule.forRoot(),
    NgxImageZoomModule.forRoot(),
    AdminModule,SharedModule
  ],  
  providers:
    [
      CookieService,
      ChatService
      , ChatGroupService
      , ScrollToBottomDirective
      , FocusDirective
      , DatePipe
      , MessageService, BackEndUrlService
      ,  { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true }
      , { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    ],
  bootstrap:
    [AppComponent],
  entryComponents: [ConfirmationComponent, confirmationProductComponent]
})
export class AppModule { }
