import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectDropDownComponent } from '../../pages/multi-select-drop-down/multi-select-drop-down.component';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { ConfirmationComponent } from '../../pages/confirmation.component';

const COMPONENT = [
  MultiSelectDropDownComponent,ConfirmationComponent
]
@NgModule({
  declarations: [COMPONENT],
  imports: [
    CommonModule, TooltipModule, FormsModule, ModalModule.forRoot()
  ], exports: [COMPONENT]
})
export class SharedModule { }
