/// <reference path="setting/setting.module.ts" />
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '../pages/not-found.component';
import { AdminComponent } from '../admin/admin.component';
import { AuthGuard } from '../authGuards/AuthGuard';
import { ProductCATestingComponent } from './product-catesting/product-catesting.component';


const routes: Routes = [
  {
    path: '',
    //canActivate: [AuthGuard],
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard'
    , data: { roles: ['dashboard'] }
    , canActivate: [AuthGuard]
    ,component: AdminComponent,
    children: [
      {
        path: ''
        , loadChildren: './dashboard/dashboard.module#DashboardModule'
      }
    ]
  },
  {
    path: 'user' 
    , data: { roles: ['user'] }
    , canActivate: [AuthGuard]
    ,component: AdminComponent,
    children: [
      { path: '', loadChildren: './manage-user/user.module#UserModule' }
    ]
  },
  {
    path: 'purchase-order',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './purchase-order/purchase-order.module#PurchaseOrderModule' }
    ]
  },
  {
    path: 'catalog-library',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './catalog-library/catalog-library.module#CatalogLibraryModule' }
    ]
  },
  {
    path: 'catalog-tool',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './product-tool/product-tool.module#ProductToolModule' }
    ]
  },
  {
    path: 'inventory',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './product-tool/product-tool.module#ProductToolModule' }
    ]
  },
  {
    path: 'amazon-canned',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './amazon-canned/amazon-canned.module#AmazonCannedModule' }
    ]
  },
  {
    path: 'ca-product',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './ca-product/ca-product.module#CaProductModule' }
    ]
  },
  {
    path: 'process-image-master',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './process-image-master/process-image-master.module#ProcessImageMasterModule' }
    ]
  },
  {
    path: 'fulfill',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './orders/orders.module#OrdersModule' }
    ]
  },
  //{
  //  path: 'verify-image',
  //  component: AdminComponent,
  //  children: [
  //    { path: '', loadChildren: './verify-image/verify-image.module#VerifyImageModule' }
  //  ]
  //},
  {
    path: 'verify-image',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './VerifyImage/verify-image.module#VerifyImageModule' }
    ]
  },
  {
    path: 'reports',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './reports/reports.module#ReportsModule' }
    ]
  },
  {
    path: 'receiving-tool',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './receiving-tool/receiving-tool.module#ReceivingToolModule' }
    ]
  },
  {
    path: 'open-order',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './open-order/open-order.module#OpenOrderModule'}
    ]
  },
  {
    path: 'settings',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './setting/setting.module#SettingModule' }
    ]
  },
  {
    path: 'task',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './task/task.module#TaskModule' }
    ]
  },
  {
    path: 'chat',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './chat/chat.module#ChatModule' }
    ]
  },



  {
    path: 'WMS',
    component: AdminComponent,
    children: [
      { path: '', loadChildren: './WMS/Warehouse/warehousedetail.module#WarehousedetailModule' }
    ]
  },
  {
    path: 'productcatesting',
    component:AdminComponent ,
    children: [
      { path: '', component:ProductCATestingComponent }
    ]
    
  }

  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRouting { }
